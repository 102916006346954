import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "../../../helpers/pipes/pipes.module";
import { CalendarModule } from "../calendar/calendar.module";
import { DatePickerPopoverComponent } from "./date-picker-popover.component";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        PipesModule,
        CalendarModule,
    ],
    declarations: [
        DatePickerPopoverComponent,
    ],
    exports: [
        DatePickerPopoverComponent,
    ],
})
export class DatePickerPopoverModule {
}
