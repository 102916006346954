import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DateTime } from "luxon";
import { ServiceEntryDto } from "../../../core/dto/serviceEntry.dto";
import { ServiceTypeDto } from "../../../core/dto/serviceType.dto";
import { UserDto } from "../../../core/dto/user.dto";

@Component({
    selector: "create-service-entry",
    templateUrl: "create-service-entry.modal.html",
    styleUrls: ["create-service-entry.modal.scss"],
})
export class CreateServiceEntryModal {
    @Input() user: UserDto;
    @Input() date: DateTime;
    @Input() ltcId: string;
    @Input() possibleServices: ServiceTypeDto[];
    public selectedServiceId: string = null;
    public quantity: number = 0;

    constructor(private modalController: ModalController) {
    }

    public onValidateClick() {
        let serviceEntry = new ServiceEntryDto();
        serviceEntry.service.id = this.selectedServiceId;
        serviceEntry.driver.id = this.user.thirdParty.id;
        serviceEntry.status.id = "HEURE_PREPA";
        serviceEntry.visitDate = this.date;
        serviceEntry.contractId = this.ltcId;
        serviceEntry.quantity = this.quantity;

        this.modalController.dismiss(serviceEntry);
    }

    public onBack() {
        this.modalController.dismiss(null);
    }
}

