import { Injectable } from "@angular/core";
import { LANGUAGES } from "../helpers/i18n/constants";

@Injectable({
    providedIn: "root",
})
export class LanguageService {
    public getUserLanguage(): LANGUAGES {
        if (navigator.language.startsWith("fr")) {
            return LANGUAGES.FRENCH;
        } else {
            return LANGUAGES.ENGLISH;
        }
    }

    public getUserLanguages(): string[] {
        return navigator.languages.slice();
    }
}

