import { NgModule } from "@angular/core";
import { AssetsHelper } from "./assets.helper";
import { DeviceHelper } from "./device.helper";
import { LoadingHelper } from "./loading.helper";
import { NetworkHelper } from "./network.helper";
import { OpenMapsHelper } from "./openMaps.helper";
import { PictureHelper } from "./picture.helper";
import { ToastHelper } from "./toast.helper";

@NgModule({
    imports: [],
    providers: [
        AssetsHelper,
        DeviceHelper,
        LoadingHelper,
        NetworkHelper,
        OpenMapsHelper,
        PictureHelper,
        ToastHelper,
    ],
})
export class CommonHelperModule {
}
