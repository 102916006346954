import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { ConstructionSite } from "./constructionSite.model";
import { Movement } from "./movement.model";
import { ThirdParty } from "./thirdParty.model";

export class Ltc extends ModelBase {
    static TABLENAME = "LongTermContracts";

    id: string = "";
    startDate: string = "";
    orderDate: string = "";
    plannedReturnDate: string = "";
    returnDate: string = "";
    wantedDays: number = 0;
    duration: number = 0;
    cumulativeMileage: number = 0;
    includedMileage: number = 0;
    contractNumber: string = "";
    bookingCode: string = "";

    constructionSite: ConstructionSite;

    isClosed: number = 0;
    // Fks
    customer: ThirdParty;
    driver: ThirdParty;
    merchant: ThirdParty;
    movement: Movement;

    constructor() {
        super(Ltc.TABLENAME);

        this.customer = new ThirdParty();
        this.driver = new ThirdParty();
        this.merchant = new ThirdParty();

        this.movement = new Movement();

        this.constructionSite = new ConstructionSite();
    }
}
