import { EventEmitter, Injectable } from "@angular/core";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { Logger } from "ionic-logging-service";
import { fromEvent } from "rxjs";
import { DeviceHelper } from "./device.helper";

@Injectable({
    providedIn: "root",
})
export class NetworkHelper {
    private connected = true;
    private connectivityChangedSubject = new EventEmitter<boolean>();
    private forceConnectivityStateSubject = new EventEmitter<boolean>();

    constructor(private logger: Logger,
                private network: Network,
                private deviceHelper: DeviceHelper) {
    }

    public initialize() {
        this.getForceConnectivityStateObservable()
            .subscribe(args => {
                if (this.connected != args) {
                    if (args) {
                        this.logger.debug(this.constructor.name, "Network connected!");
                    } else {
                        this.logger.debug(this.constructor.name, "Network disconnected :-(");
                    }
                }
                this.connected = args;
                this.publishConnectivityChanged(this.connected);
            });

        if (this.deviceHelper.isRunningOnDevice()) {
            this.logger.debug(this.constructor.name, "Waiting for network events, mobile mode");

            this.network.onConnect().subscribe(() => {
                this.logger.debug(this.constructor.name, "Network connected!");
                this.connected = true;
                this.publishConnectivityChanged(this.connected);
            });

            this.network.onDisconnect().subscribe(() => {
                this.logger.debug(this.constructor.name, "Network disconnected :-(");
                this.connected = false;
                this.publishConnectivityChanged(this.connected);
            });
        } else {
            this.logger.debug(this.constructor.name, "Waiting for network events, browser mode");

            fromEvent(window, "online").subscribe(() => {
                this.logger.debug(this.constructor.name, "Network connected!");
                this.connected = true;
                this.publishConnectivityChanged(this.connected);
            });

            fromEvent(window, "offline").subscribe(() => {
                this.logger.debug(this.constructor.name, "Network disconnected :-(");
                this.connected = false;
                this.publishConnectivityChanged(this.connected);
            });
        }
    }

    public getConnectivityObservable(): EventEmitter<boolean> {
        return this.connectivityChangedSubject;
    }

    public publishConnectivityChanged(state: boolean) {
        this.connectivityChangedSubject.emit(state);
    }

    public isConnected(): boolean {
        return this.connected;
    }

    public getForceConnectivityStateObservable(): EventEmitter<boolean> {
        return this.forceConnectivityStateSubject;
    }

    public publishForceConnectivityStateChanged(state: boolean) {
        this.forceConnectivityStateSubject.emit(state);
    }
}
