import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Attachment } from "../models/attachment.model";
import { AttachedDocumentDto } from "./attachedDocument.dto";
import { AttachmentKindDto } from "./attachmentKind.dto";
import { BaseDto } from "./base/base.dto";

export class AttachmentDto extends BaseDto<Attachment> {
    public id: string = "";
    public wording: string = "";
    public date: DateTime = null;
    public file: string = "";
    public attachedDocuments: AttachedDocumentDto[] = [];
    public txt: string = "";
    public object: string = "";
    public key: string = "";
    public attachmentKind: AttachmentKindDto = null;

    // volatile
    public modified: boolean = false;

    constructor() {
        super();
        this.attachmentKind = new AttachmentKindDto();
    }

    public static fromModel(model: Attachment): AttachmentDto {
        let attachmentDto = new AttachmentDto();

        attachmentDto.id = model.id;
        attachmentDto.wording = model.wording;
        attachmentDto.attachmentKind = AttachmentKindDto.fromModel(model.attachmentKind);
        attachmentDto.date = DateTime.fromISO(model.date);
        attachmentDto.file = model.file;
        attachmentDto.attachedDocuments = AttachedDocumentDto.fromModels(model.attachedDocuments);
        attachmentDto.txt = model.txt;
        attachmentDto.object = model.object;
        attachmentDto.key = model.key;
        attachmentDto.modified = (model.modified == 1);

        return attachmentDto;
    }

    public static fromBody(body: any, object: string, key: string): AttachmentDto {
        let attachmentDto = new AttachmentDto();

        attachmentDto.id = body.id;
        attachmentDto.date = DateHelper.tryFromFormat(body.date, DATE_NODEJS_FORMAT);
        attachmentDto.wording = body.wording;
        attachmentDto.txt = body.txt;
        attachmentDto.modified = false;
        attachmentDto.object = object;
        attachmentDto.key = key;

        if (body.attachmentKind) {
            attachmentDto.attachmentKind = AttachmentKindDto.fromBody(body.attachmentKind);
        }

        if (body.file) {
            attachmentDto.file = body.file;
        }

        if (body.attachedDocuments) {
            body.attachedDocuments.forEach(attachedDocument => {
                attachmentDto.attachedDocuments.push(AttachedDocumentDto.fromBody(attachedDocument));
            });
        }

        return attachmentDto;
    }

    public toModel(): Attachment {
        let attachment = new Attachment();

        attachment.id = this.id;
        attachment.date = DateHelper.tryToISO(this.date);
        attachment.wording = this.wording;
        attachment.txt = this.txt;
        attachment.attachmentKind = this.attachmentKind.toModel();
        attachment.file = this.file;
        attachment.modified = 0;
        attachment.object = this.object;
        attachment.key = this.key;

        this.attachedDocuments.forEach(attachedDocument => {
            attachment.attachedDocuments.push(attachedDocument.toModel());
        });

        return attachment;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.date = DateHelper.tryToFormat(this.date, DATE_NODEJS_FORMAT);

        let array = [];
        this.attachedDocuments.forEach(document => {
            array.push(document.toBody());
        });
        clone.attachedDocuments = array;

        return clone;
    }
}
