import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { GyzmoButtonModule } from "../../../gyzmo-commons/components/gyzmo-button/gyzmo-button.module";
import { GtagModule } from "../../../gyzmo-commons/gtag/gtag.module";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { CreateServiceEntryModal } from "./create-service-entry.modal";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        TranslateModule,
        GtagModule,
        GyzmoButtonModule,
    ],
    declarations: [CreateServiceEntryModal],
    providers: [],
})
export class CreateServiceEntryModule {
}
