export const HOUR_FORMAT = "HH:mm";
export const HOURS_MINUTES_SECONDS_FORMAT = "HH:mm:ss";
export const HOURS_MINUTES_SECONDS_FORMAT_FOR_DURATION = "hh:mm:ss";
export const DATE_NODEJS_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const PARSE_DATE_HOUR_FORMAT: string = "yyyy-MM-dd HH:mm";
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_FORMAT_FOR_PICKER = "yyyy-MM-dd";
export const TOAST_DELAY: number = 5000; // Delai d'affichage par defaut d'un toast
export const PICTURE_WIDTH: number = 1000; // Largeur picture
export const PICTURE_HEIGHT: number = 1000; // Hauteur picture
export const PICTURE_QUALITY: number = 65; // % compression picture
