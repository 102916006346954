import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { TimeEntry } from "../../models/timeEntry";

@Injectable({
    providedIn: "root",
})
export class TimeEntryDbDao extends DbDaoBase<TimeEntry> {
    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public list(kindFilter: string): Promise<TimeEntry[]> {
        let selectQuery = "SELECT * FROM " + TimeEntry.TABLENAME + ";";

        return this.listByQuery(selectQuery);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + TimeEntry.TABLENAME + "_id"
                    + " ON " + TimeEntry.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + TimeEntry.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY, "
                    + "visitDate TEXT, "
                    + "quantity TEXT, "
                    + "additionalQuantity TEXT, "
                    + "contractId TEXT, "
                    + "service TEXT, "
                    + "status TEXT, "
                    + "driver TEXT "
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + TimeEntry.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + TimeEntry.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<TimeEntry> {
        let selectQuery = "SELECT * FROM " + TimeEntry.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let returnCheckStatus = this.rowToModel(data.rows[0]);
                    let hydratationPromises = [];
                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return returnCheckStatus;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return TimeEntry.TABLENAME;
    }

    protected rowToModel(row: any): TimeEntry {
        let timeEntry = new TimeEntry();

        timeEntry.id = row.id;
        timeEntry.visitDate = row.visitDate;
        timeEntry.quantity = row.quantity;
        timeEntry.additionalQuantity = row.additionalQuantity;
        timeEntry.contractId = row.contractId;

        timeEntry.service = JSON.parse(row.service);
        timeEntry.status = JSON.parse(row.status);
        timeEntry.driver = JSON.parse(row.driver);

        return timeEntry;
    }

    public save(timeEntry: TimeEntry): Promise<TimeEntry> {
        let promises = [];

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + TimeEntry.TABLENAME + " ("
                            + "id, "
                            + "visitDate, "
                            + "quantity, "
                            + "additionalQuantity, "
                            + "contractId, "
                            + "service, "
                            + "status, "
                            + "driver "
                            + ") VALUES ("
                            + this.getValue(timeEntry.id)
                            + this.getValue(timeEntry.visitDate)
                            + this.getValue(timeEntry.quantity)
                            + this.getValue(timeEntry.additionalQuantity)
                            + this.getValue(timeEntry.contractId)
                            + this.getValueAsJsonString(timeEntry.service)
                            + this.getValueAsJsonString(timeEntry.status)
                            + this.getValueAsJsonString(timeEntry.driver, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return timeEntry;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }

    private listByQuery(query: string): Promise<TimeEntry[]> {
        return this.sqlProvider.query(query)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let returnTimeEntries: TimeEntry[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    returnTimeEntries.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return returnTimeEntries;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
