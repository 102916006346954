import { NgModule } from "@angular/core";
import { CommonDbDaoModule } from "../../../gyzmo-commons/dao/db/common.db.dao.module";
import { AddressDbDao } from "./address.db.dao";
import { AttachmentDbDao } from "./attachment.db.dao";
import { ConstructionSiteDbDao } from "./constructionSite.db.dao";
import { ContactDetailsDbDao } from "./contactDetails.db.dao";
import { DriverInfoDbDao } from "./driverInfo.db.dao";
import { HourTypeDbDao } from "./hourType.db.dao";
import { LtcDbDao } from "./ltc.db.dao";
import { OfflineModeDbDao } from "./offlineMode.db.dao";
import { ServerDbDao } from "./server.db.dao";
import { ServiceEntryDbDao } from "./serviceEntry.db.dao";
import { ServiceTypeDbDao } from "./serviceType.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";
import { TimeEntryDbDao } from "./timeEntry.db.dao";
import { UserDbDao } from "./user.db.dao";

@NgModule({
    imports: [CommonDbDaoModule],
    providers: [
        AddressDbDao,
        AttachmentDbDao,
        ConstructionSiteDbDao,
        ContactDetailsDbDao,
        DriverInfoDbDao,
        HourTypeDbDao,
        LtcDbDao,
        OfflineModeDbDao,
        ServerDbDao,
        ThirdPartyDbDao,
        TimeEntryDbDao,
        UserDbDao,
        ServiceTypeDbDao,
        ServiceEntryDbDao,
    ],
})
export class DbDaoModule {
}
