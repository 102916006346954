import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Logger } from "ionic-logging-service";
import { environment } from "../../../environments/environment";
import { AssetsHelper } from "../../helpers/assets.helper";
import { CameraBasePage } from "../camera.base.page";

@Component({
    templateUrl: "browserCamera.page.html",
    styleUrls: ["browserCamera.page.scss"],
})
export class BrowserCameraModal extends CameraBasePage {
    @ViewChild("screenshotVideo", { static: false }) video: ElementRef;
    @ViewChild("customCanvas", { static: false }) canvas: ElementRef;

    @Input() showDebugInfo = false;
    @Input() width = 1000;
    @Input() height = 1000;

    templateFilename: string;

    flash = false;
    flashAvailable = false;

    facingModeAvailable = false;

    ready = false;
    takingPhoto = false;

    frontCamera = false;

    cameraSettings: MediaTrackSettings;
    videoConstraints: MediaStreamConstraints = {
        audio: false,
        video: {
            facingMode: "environment",
            width: { min: 600, ideal: 600, max: 800 },
            height: { min: 600, ideal: 600, max: 800 },
        },
    };
    activeTrack: MediaStreamTrack;

    constructor(private logger: Logger,
                assetsHelper: AssetsHelper,
                private viewController: ModalController) {
        super(assetsHelper);
    }

    private static hasGetUserMedia() {
        return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    }

    async ionViewDidEnter() {
        if (environment.mockCamera) {
            this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
        } else if (!BrowserCameraModal.hasGetUserMedia()) {
            this.logger.error(this.constructor.name, "getUserMedia() is not supported by your browser");
            this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
        } else {
            if (this.width) {
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).min = Math.floor(this.width / 1.5);
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).ideal = Math.floor(this.width);
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).max = Math.floor(this.width * 1.5);
            }
            if (this.height) {
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).min = Math.floor(this.height / 1.5);
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).ideal = Math.floor(this.height);
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).max = Math.floor(this.height * 1.5);
            }
            this.startCamera();

            this.templateFilename = await this.getTemplateFilename(this.photoType, this.inspectionModel);
        }
    }

    public dismiss(data?: any): void {
        this.stopCamera();
        this.viewController.dismiss(data);
    }

    public takePicture(): void {
        this.canvas.nativeElement.width = this.video.nativeElement.videoWidth;
        this.canvas.nativeElement.height = this.video.nativeElement.videoHeight;
        this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0);

        let dataURL = this.canvas.nativeElement.toDataURL("image/jpeg", 0.7);
        this.dismiss(dataURL);
    }

    async toggleFlash() {
        this.ready = false;

        this.flash = !this.flash;
        await this.activeTrack.applyConstraints({
            // @ts-ignore
            advanced: [{ torch: this.flash }],
        });

        this.ready = true;
    }

    async swapCamera() {
        this.stopCamera();

        this.frontCamera = !this.frontCamera;
        (this.videoConstraints.video as MediaTrackConstraints).facingMode = this.frontCamera ? "user" : "environment";

        this.startCamera();
    }

    private startCamera() {
        navigator.mediaDevices.getUserMedia(this.videoConstraints)
            .then(async mediaStream => {
                this.video.nativeElement.srcObject = mediaStream;
                this.video.nativeElement.onloadedmetadata = () => {
                    for (const videoTrack of mediaStream.getVideoTracks()) {
                        if (videoTrack.enabled) {
                            this.cameraSettings = videoTrack.getSettings();
                            this.activeTrack = videoTrack;

                            let capabilities = videoTrack.getCapabilities();
                            this.facingModeAvailable = capabilities.facingMode.length > 0;
                            this.flashAvailable = capabilities["torch"] == true;
                        }
                    }

                    this.video.nativeElement.play();

                    this.ready = true;
                };
            })
            .catch(async reason => {
                this.logger.error(this.constructor.name, reason);
                this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
            });
    }

    private stopCamera() {
        this.ready = false;

        let mediaStream = this.video.nativeElement.srcObject;
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
        }
    }
}
