import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Movement } from "../models/movement.model";
import { BaseDto } from "./base/base.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class MovementDto extends BaseDto<Movement> {
    id: string = "";
    documentNumber: string = "";
    contractNumber: string = "";
    startDate: DateTime = null;
    returnDate: DateTime = null;
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = 0;
    returnMileage2: number = 0;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = 0;
    startMileage2: number = 0;
    isClosed: boolean = false;
    customer: ThirdPartyDto = null;
    relatedTable: string = null;

    constructor() {
        super();

        this.customer = new ThirdPartyDto();
    }

    public static fromBody(body: any): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = body.id;
        movementDto.documentNumber = body.documentNumber;
        movementDto.contractNumber = body.contractNumber;

        movementDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        movementDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        movementDto.returnFuel = body.returnFuel;
        movementDto.returnFuel2 = body.returnFuel2;
        movementDto.returnMileage = body.returnMileage;
        movementDto.returnMileage2 = body.returnMileage2;
        movementDto.startFuel = body.startFuel;
        movementDto.startFuel2 = body.startFuel2;
        movementDto.startMileage = body.startMileage;
        movementDto.startMileage2 = body.startMileage2;
        movementDto.isClosed = body.isClosed;

        if (body.relatedTables) {
            movementDto.relatedTable = body.relatedTables.wording;
        }

        if (body.customer) {
            movementDto.customer = ThirdPartyDto.fromBody(body.customer);
        }

        return movementDto;
    }

    static fromModel(movement: Movement): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = movement.id;

        movementDto.documentNumber = movement.documentNumber;
        movementDto.contractNumber = movement.contractNumber;

        movementDto.startDate = DateTime.fromISO(movement.startDate);
        movementDto.returnDate = DateTime.fromISO(movement.returnDate);
        movementDto.returnFuel = movement.returnFuel;
        movementDto.returnFuel2 = movement.returnFuel2;
        movementDto.returnMileage = movement.returnMileage;
        movementDto.returnMileage2 = movement.returnMileage2;
        movementDto.startFuel = movement.startFuel;
        movementDto.startFuel2 = movement.startFuel2;
        movementDto.startMileage = movement.startMileage;
        movementDto.startMileage2 = movement.startMileage2;
        movementDto.relatedTable = movement.relatedTable;
        movementDto.isClosed = movement.isClosed == 1;

        movementDto.customer = ThirdPartyDto.fromModel(movement.customer);

        return movementDto;
    }

    toModel() {
        let movement = new Movement();

        movement.id = this.id;
        movement.documentNumber = this.documentNumber;
        movement.contractNumber = this.contractNumber;

        movement.startDate = DateHelper.tryToISO(this.startDate);
        movement.returnDate = DateHelper.tryToISO(this.returnDate);
        movement.returnFuel = this.returnFuel;
        movement.returnFuel2 = this.returnFuel2;
        movement.returnMileage = this.returnMileage;
        movement.returnMileage2 = this.returnMileage2;
        movement.startFuel = this.startFuel;
        movement.startFuel2 = this.startFuel2;
        movement.startMileage = this.startMileage;
        movement.startMileage2 = this.startMileage2;
        movement.relatedTable = this.relatedTable;
        movement.isClosed = this.isClosed ? 1 : 0;

        return movement;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.returnDate = DateHelper.tryToFormat(this.returnDate, DATE_NODEJS_FORMAT);

        clone.customer = this.customer.toBody();

        return clone;
    }
}

