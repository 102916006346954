import { Injectable } from "@angular/core";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { ModalController } from "@ionic/angular";
import { PARSE_DATE_HOUR_FORMAT } from "../interfaces/constants";
import { DateProvider } from "../interfaces/dateProvider";
import { DrawModal } from "../modals/draw/draw.modal";
import { GeolocationService } from "../services/geolocation.service";
import { DeviceHelper } from "./device.helper";
import { NavControllerExtended } from "./navControllerExtended";

@Injectable({
    providedIn: "root",
})
export class PictureHelper {
    constructor(private deviceHelper: DeviceHelper,
                private dateProvider: DateProvider,
                private photoViewer: PhotoViewer,
                private modalController: ModalController,
                private geolocationService: GeolocationService,
                private navCtrl: NavControllerExtended) {
    }

    public static resize(uriImageData: string,
                         pictureWidth: number,
                         pictureHeight: number,
                         pictureQuality: number): Promise<string> {
        return new Promise(
            (resolve, reject) => {
                if (uriImageData == null) {
                    return reject();
                }

                const canvas = document.createElement("canvas"),
                    context = canvas.getContext("2d"),
                    image = new Image();

                image.addEventListener(
                    "load",
                    ev => {
                        let maxWidth = pictureWidth,
                            maxHeight = pictureHeight,
                            imageWidth = image.width,
                            imageHeight = image.height;

                        if (imageWidth > imageHeight) {
                            if (imageWidth > maxWidth) {
                                imageHeight *= maxWidth / imageWidth;
                                imageWidth = maxWidth;
                            }
                        } else {
                            if (imageHeight > maxHeight) {
                                imageWidth *= maxHeight / imageHeight;
                                imageHeight = maxHeight;
                            }
                        }

                        canvas.width = imageWidth;
                        canvas.height = imageHeight;
                        context.drawImage(image, 0, 0, canvas.width, canvas.height);
                        resolve(canvas.toDataURL(this.extractMimeTypeOfUrl(uriImageData), pictureQuality));
                    },
                    false);
                image.src = uriImageData;
            });
    }

    public static encodeToBase64(arrayBuffer: ArrayBuffer): string {
        let base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
        return this.guessMime(base64String.charAt(0)) + base64String;
    }

    public static addLegend(uriImageData: string,
                            legend: string,
                            textSize: number,
                            pictureQuality: number): Promise<string> {
        return new Promise((resolve, reject) => {
            if (uriImageData == null) {
                return reject();
            }

            const canvas = document.createElement("canvas"),
                context = canvas.getContext("2d"),
                image = new Image();

            image.addEventListener(
                "load",
                ev => {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    context.drawImage(image, 0, 0, image.width, image.height);

                    context.font = textSize + "px Georgia";
                    let textMargin = 4;
                    let yCoord = image.height - (textSize + textMargin);

                    let lines = legend.split("\n");

                    for (let i = lines.length - 1; i >= 0; i--) {
                        let textMetrics: TextMetrics = context.measureText(lines[i]);
                        context.fillStyle = "#FFF";
                        context.globalAlpha = 0.5;
                        context.fillRect(image.width - textMetrics.width - 10,
                            yCoord,
                            textMetrics.width + 20,
                            textSize + textMargin);

                        context.fillStyle = "#333";
                        context.globalAlpha = 1;
                        context.fillText(lines[i],
                            image.width - textMetrics.width - 5,
                            yCoord + (textSize));

                        yCoord -= textSize + textMargin;
                    }

                    resolve(canvas.toDataURL("image/jpeg", pictureQuality));
                },
                false);
            image.src = uriImageData;
        });
    }

    private static guessMime(path: string) {
        switch (path) {
            case "i": {
                return "data:image/png;base64,";
            }
            case "R": {
                return "data:image/gif;base64,";
            }
            case "J": {
                return "data:application/pdf;base64,";
            }
            case "P": {
                return "data:text/plain;base64,";
            }
            default: {
                // / = JPEG
                return "data:image/jpeg;base64,";
            }
        }
    }

    private static extractMimeTypeOfUrl(url: string) {
        return url.substr("data:".length, url.indexOf(";") - "data:".length);
    }

    public async watermarkPicture(imageData: string,
                                  pictureQuality: number): Promise<string> {
        let legend = this.dateProvider.now().toFormat(PARSE_DATE_HOUR_FORMAT);
        let lastKnownLocation = await this.geolocationService.getCurrentPosition(false);
        if (lastKnownLocation) {
            legend += "\n";
            legend += " à " + lastKnownLocation.latitude.toFixed(3) + ", " + lastKnownLocation.longitude.toFixed(3);
        }

        let result = await PictureHelper.addLegend(imageData,
            legend,
            20,
            pictureQuality);

        return result;
    }

    public viewImage(imageData: string, canModifyPhoto: boolean): Promise<string> {
        this.navCtrl.params.clearParams();
        this.navCtrl.params.addParam("imageData", imageData);
        this.navCtrl.params.addParam("canModifyPhoto", canModifyPhoto);

        if (canModifyPhoto) {
            return new Promise<string>(
                (resolve) => {
                    this.modalController.create({
                        component: DrawModal,
                        backdropDismiss: false,
                    })
                        .then(modal => {
                            modal.present();
                            modal.onDidDismiss()
                                .then(result => {
                                    if (result.data) {
                                        resolve(result.data.base64);
                                    } else {
                                        resolve(null);
                                    }
                                });
                        });
                });
        } else {
            if (this.deviceHelper.isRunningOnDevice()) {
                const options = {
                    share: false, // default is false
                    closeButton: true, // default is true
                    copyToReference: false, // default is false
                };
                this.photoViewer.show(imageData, "", options);
            } else {
                let newWindow = window.open("");
                newWindow.document.write("<body style='height: 100%; width: 100%'><img src='" + imageData + "' style='max-height: 100%; max-width: 100%' /></body>");
                newWindow.document.close();
            }
            return Promise.resolve(null);
        }
    }
}
