import { Injectable } from "@angular/core";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { KeyValue } from "../../gyzmo-commons/models/keyValue.model";
import { GeneralParametersWs } from "../../gyzmo-commons/ws/generalParameters.ws";
import { UserDto } from "../dto/user.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { HourTypeService } from "./hourType.service";
import { ServiceTypeService } from "./serviceType.service";

@Injectable({ providedIn: "root" })
export class DefaultDataDownloaderService {
    constructor(private serversConnectionsProvider: ServersConnectionsProvider,
                private keyValueDbDao: KeyValueDbDao,
                private hourTypeService: HourTypeService,
                private serviceTypeService: ServiceTypeService,
                private generalParametersWs: GeneralParametersWs) {
    }

    public async onConnectionDefaultData(user: UserDto): Promise<void> {
        let promises = [];

        promises.push(this.hourTypeService.synchronize());
        promises.push(this.serviceTypeService.synchronize());
        promises.push(this.generalParametersWs.getValue(this.serversConnectionsProvider.getPrimaryServerConnection(),
            "IMOB_CLOCK", "PARAMETERS").then(value => {
            this.keyValueDbDao.save(new KeyValue("IMOB_CLOCK_PARAMETERS", value));
        }));

        await Promise.all(promises);
    }
}
