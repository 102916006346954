import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DateHelper } from "../../helpers/date.helper";
import { DateProvider } from "../../interfaces/dateProvider";
import { CachedValue } from "../../models/cachedValue.model";
import { AppSqlProvider } from "../../persistence/app.sql.provider";
import { DbDaoBase } from "./base/db.dao.base";

@Injectable({
    providedIn: "root",
})
export class CacheDbDao extends DbDaoBase<CachedValue> {
    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private dateProvider: DateProvider) {
        super(logger);
    }

    public getCachedKeys(): Promise<string[]> {
        let query = "SELECT key FROM " + CachedValue.TABLENAME + ";";

        return this.sqlProvider.query(query)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let keys: string[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    let key = data.rows[i].key;

                    keys.push(key);
                }

                return keys;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public isCached(key: string): Promise<boolean> {
        const selectQuery = "SELECT key FROM " + CachedValue.TABLENAME + " WHERE key = '" + key + "' AND expirationDate >='" + this.dateProvider.now().toISO() + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                return data.rows.length > 0;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return false;
            });
    }

    public cleanCache() {
        const selectQuery = "DELETE FROM " + CachedValue.TABLENAME + " WHERE expirationDate <'" + this.dateProvider.now().toISO() + "';";
        return this.sqlProvider.query(selectQuery);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + CachedValue.TABLENAME + "_key"
                    + " ON " + CachedValue.TABLENAME + "(key);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + CachedValue.TABLENAME + "_expirationDate"
                + " ON " + CachedValue.TABLENAME + "(expirationDate);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        const query = "CREATE TABLE IF NOT EXISTS " + CachedValue.TABLENAME
                      + " ("
                      + "key TEXT PRIMARY KEY,"
                      + "value TEXT,"
                      + "date TEXT,"
                      + "expirationDate TEXT"
                      + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(key: string): Promise<any> {
        const selectQuery = "DELETE FROM " + CachedValue.TABLENAME + " WHERE key='" + key + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        const selectQuery = "DELETE FROM " + CachedValue.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    // @ts-ignore
    public get(key: string, hydrate: boolean = false): Promise<CachedValue> {
        const selectQuery = "SELECT * FROM " + CachedValue.TABLENAME + " WHERE key = '" + key + "' AND expirationDate >='" + this.dateProvider.now().toISO() + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return CachedValue.fromRow(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return CachedValue.TABLENAME;
    }

    protected rowToModel(row: any): CachedValue {
        return CachedValue.fromRow(row);
    }

    public save(cachedValue: CachedValue): Promise<CachedValue> {
        const query = "INSERT OR REPLACE INTO " + CachedValue.TABLENAME + " (key, value, date, expirationDate) VALUES ("
                      + this.getValue(cachedValue.key)
                      + this.getValue(cachedValue.value)
                      + this.getValue(DateHelper.tryToISO(cachedValue.date))
                      + this.getValue(DateHelper.tryToISO(cachedValue.expirationDate), true)
                      + ");";

        return this.sqlProvider.query(query)
            .then(() => {
                return cachedValue;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public dropTable(): Promise<void> {
        const query = "DROP TABLE IF EXISTS " + CachedValue.TABLENAME + ";";

        return this.sqlProvider.query(query)
            .then(() => {
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
