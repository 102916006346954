import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../../gyzmo-commons/helpers/network.helper";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";
import { AppVersionService } from "../../gyzmo-commons/services/appVersion.service";
import { LanguageService } from "../../gyzmo-commons/services/language.service";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ServerDto } from "../dto/server.dto";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";
import { OfflineModeService } from "../services/offlineMode.service";

export const KEY: string = "serverAddress";

@Injectable({
    providedIn: "root",
})
export class ServersConnectionsProvider {
    protected serversConnection: ServerConnection = null;

    constructor(private deviceHelper: DeviceHelper,
                private appVersionService: AppVersionService,
                private languageService: LanguageService,
                private networkHelper: NetworkHelper,
                private httpClientProvider: HttpClientProvider,
                private httpErrorHandler: HttpErrorHandler,
                private serverDbDao: ServerDbDao,
                private offlineModeService: OfflineModeService) {
    }

    public async initialize(): Promise<void> {
        let servers = await this.serverDbDao.list();

        if (servers.length <= 0) {
            await this.changeConfiguration(0);
        } else {
            this.serversConnection = new ServerConnection(
                this.deviceHelper,
                this.languageService,
                this.appVersionService,
                this.networkHelper,
                ServerDto.fromModel(servers[0]),
                this.serverDbDao,
                this.httpClientProvider,
                this.httpErrorHandler,
                this.offlineModeService);
        }
    }

    changeConfiguration(connectionIndex: number): Promise<void> {
        return this.serverDbDao.deleteAll()
            .then(() => {
                let serverDto = this.getActiveServerConfiguration(connectionIndex);

                let promises = [];
                promises.push(this.serverDbDao.save(serverDto.toModel()));

                return Promise.all(promises)
                    .then(value => {
                        return this.initialize();
                    });
            });
    }

    public getServerConnection(): ServerConnection {
        return this.serversConnection;
    }

    public getPrimaryServerConnection(): ServerConnection {
        return this.getServerConnection();
    }

    public getServerConfigurationList(): ServerDto[] {
        let servers = [];
        let i = 0;

        environment.servers.forEach(server => {
            let serverDto = ServerDto.fromServerConfiguration(server);
            serverDto.id = i;
            servers.push(serverDto);
            i++;
        });

        return servers;
    }

    private getActiveServerConfiguration(connectionIndex: number): ServerDto {
        let serverDto = ServerDto.fromServerConfiguration(environment.servers[connectionIndex]);
        serverDto.id = connectionIndex;

        return serverDto;
    }
}
