import { ServiceType } from "../models/serviceType";
import { BaseDto } from "./base/base.dto";

export class ServiceTypeDto extends BaseDto<ServiceType> {
    public id: string = "";
    public wording: string = "";

    static fromBody(body: any): ServiceTypeDto {
        let serviceTypeDto = new ServiceTypeDto();

        serviceTypeDto.id = body.field0;
        serviceTypeDto.wording = body.field1;

        return serviceTypeDto;
    }

    static fromModel(hourType: ServiceType) {
        let serviceTypeDto = new ServiceTypeDto();

        serviceTypeDto.id = hourType.id;
        serviceTypeDto.wording = hourType.wording;

        return serviceTypeDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): ServiceType {
        let serviceType = new ServiceType();

        serviceType.id = this.id;
        serviceType.wording = this.wording;

        return serviceType;
    }
}
