import { Injectable } from "@angular/core";
import { CacheDbDao } from "../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "../dao/db/address.db.dao";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { ConstructionSiteDbDao } from "../dao/db/constructionSite.db.dao";
import { ContactDetailsDbDao } from "../dao/db/contactDetails.db.dao";
import { DriverInfoDbDao } from "../dao/db/driverInfo.db.dao";
import { HourTypeDbDao } from "../dao/db/hourType.db.dao";
import { LtcDbDao } from "../dao/db/ltc.db.dao";
import { OfflineModeDbDao } from "../dao/db/offlineMode.db.dao";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ServiceEntryDbDao } from "../dao/db/serviceEntry.db.dao";
import { ServiceTypeDbDao } from "../dao/db/serviceType.db.dao";
import { ThirdPartyDbDao } from "../dao/db/thirdParty.db.dao";
import { TimeEntryDbDao } from "../dao/db/timeEntry.db.dao";
import { UserDbDao } from "../dao/db/user.db.dao";

@Injectable({
    providedIn: "root",
})
export class DaoProvider {
    constructor(private addressDbDao: AddressDbDao,
                private attachmentDbDao: AttachmentDbDao,
                private constructionSiteDbDao: ConstructionSiteDbDao,
                private contactDetailsDbDao: ContactDetailsDbDao,
                private driverInfoDbDao: DriverInfoDbDao,
                private hourTypeDbDao: HourTypeDbDao,
                private serviceTypeDbDao: ServiceTypeDbDao,
                private ltcDbDao: LtcDbDao,
                private offlineModeDbDao: OfflineModeDbDao,
                private serverDbDao: ServerDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private timeEntryDbDao: TimeEntryDbDao,
                private userDbDao: UserDbDao,
                private serviceEntryDbDao: ServiceEntryDbDao,
                // commons
                private cacheDbDao: CacheDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private versionDbDao: VersionDbDao) {
    }

    public getAllDaoList() {
        return [
            this.addressDbDao,
            this.attachmentDbDao,
            this.constructionSiteDbDao,
            this.contactDetailsDbDao,
            this.driverInfoDbDao,
            this.hourTypeDbDao,
            this.serviceTypeDbDao,
            this.ltcDbDao,
            this.offlineModeDbDao,
            this.serverDbDao,
            this.thirdPartyDbDao,
            this.timeEntryDbDao,
            this.userDbDao,
            this.serviceEntryDbDao,
            // commons
            this.cacheDbDao,
            this.keyValueDbDao,
            this.versionDbDao];
    }

    public getOnDisconnectDaoList() {
        return [
            this.addressDbDao,
            this.attachmentDbDao,
            this.constructionSiteDbDao,
            this.contactDetailsDbDao,
            this.driverInfoDbDao,
            this.hourTypeDbDao,
            this.serviceTypeDbDao,
            this.ltcDbDao,
            this.offlineModeDbDao,
            this.serverDbDao,
            this.thirdPartyDbDao,
            this.timeEntryDbDao,
            this.userDbDao,
            this.serviceEntryDbDao,
            // commons
            this.cacheDbDao,
            // Not those ones !
            // this.keyValueDbDao,
            // this.versionDbDao
        ];
    }
}
