import { Injectable } from "@angular/core";
import { HourTypeDbDao } from "../dao/db/hourType.db.dao";
import { HourTypeWsDao } from "../dao/ws/hourType.ws.dao";
import { HourTypeDto } from "../dto/hourType.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class HourTypeService {
    constructor(private hourTypeWsDao: HourTypeWsDao,
                private hourTypeDbDao: HourTypeDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getDbList(kindFilter: string): Promise<HourTypeDto[]> {
        return this.hourTypeDbDao.list(kindFilter)
            .then(values => {
                let result = [];

                values.forEach(value => {
                    result.push(HourTypeDto.fromModel(value));
                });

                return result;
            });
    }

    public getById(id: string): Promise<HourTypeDto> {
        return this.hourTypeDbDao.get(id)
            .then(value => {
                return HourTypeDto.fromModel(value);
            });
    }

    public synchronize(): Promise<void> {
        return this.hourTypeWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(hourTypeDtos => {
                let promises = [];

                hourTypeDtos.forEach(hourTypeDto => {
                    promises.push(this.hourTypeDbDao.save(hourTypeDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }
}
