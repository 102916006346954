import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BrowserCameraModal } from "../pages/browser-camera/browserCamera.page";

@Injectable({
    providedIn: "root",
})
export class CameraService {
    constructor(public modalController: ModalController) {
    }

    public getPicture(photoType?: string, inspectionModel?: string, resolution?: { width: number; height: number }, showDebugInfo = false): Promise<string> {
        return new Promise<string>(
            (resolve, reject) => {
                this.modalController.create({
                    component: BrowserCameraModal,
                    backdropDismiss: false,
                    componentProps: {
                        photoType: photoType,
                        inspectionModel: inspectionModel,
                        showDebugInfo: showDebugInfo,
                        width: resolution ? resolution.width : null,
                        height: resolution ? resolution.height : null,
                    },
                })
                    .then(modal => {
                        modal.present();
                        modal.onDidDismiss().then(result => {
                            if (result && result.data) {
                                resolve(result.data);
                            } else {
                                reject();
                            }
                        });
                    });
            });
    }
}
