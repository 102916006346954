import { Injectable } from "@angular/core";
import { ServiceTypeDto } from "src/core/dto/serviceType.dto";
import { WsDaoBase } from "../../../gyzmo-commons/dao/ws/base/ws.dao.base";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { ServerConnection } from "../../http/serverConnection";
import { ZoomWsDao } from "./zoom.ws.dao";

@Injectable({
    providedIn: "root",
})
export class ServiceTypeWsDao extends WsDaoBase<ServiceTypeDto> {

    constructor( private zoomWsDao: ZoomWsDao) {
super();
}

    public getById(serverConnection: ServerConnection, id: string): Promise<ServiceTypeDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: ServiceTypeDto): Promise<ServiceTypeDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<ServiceTypeDto[]> {
        return new Promise<ServiceTypeDto[]>(async (resolve, reject) => {


            this.zoomWsDao.execute(serverConnection, "M0K109K3D001022K0018",'')
            .then(zoomResults => {
                let results = [];
                console.log(zoomResults);
                zoomResults.forEach(zoomResult => {
                    let serviceTypeDto = ServiceTypeDto.fromBody(zoomResult);
                    results.push(serviceTypeDto);
                });

                resolve(results);
            });
        });
    }
}
