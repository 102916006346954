import { Component, Input } from "@angular/core";
import { LtcDto } from "../../../core/dto/ltc.dto";
import { AddressHelper } from "../../../core/helpers/address.helper";

@Component({
    selector: "contract-result",
    templateUrl: "contract-result.component.html",
    styleUrls: ["contract-result.component.scss"],
})
export class ContractResultComponent {
    @Input() ltc: LtcDto;
    addressHelper = AddressHelper;

}
