import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class TimeEntry extends ModelBase {
    static TABLENAME = "TimeEntries";

    id: string = "";
    visitDate: string = "";
    quantity: number = 0;
    additionalQuantity: number = 0;
    contractId: string = "";

    driver: { id: string } = { id: null };
    service: { id: string } = { id: null };
    status: { id: string } = { id: null };

    constructor() {
        super(TimeEntry.TABLENAME);
    }
}
