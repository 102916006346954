/* eslint-disable */
import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Logger } from "ionic-logging-service";
import { DeviceHelper } from "../helpers/device.helper";
import { Content, EventParams } from "./gtag-definitions";

@Injectable()
export class GtagService {
    constructor(private logger: Logger,
                private deviceHelper: DeviceHelper) {
    }

    /** Disables the analytics for the given measurement id. Defaults to the targetId from the global confguration when unspecified.
     *
     * @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out */
    public async disable(): Promise<void> {
        if (this.deviceHelper.isRunningOnDevice()) {
            await FirebaseAnalytics.disable()
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
        }
    }

    /** Helper function to re-enable analytics after it was disabled */
    public async enable(): Promise<void> {
        if (this.deviceHelper.isRunningOnDevice()) {
            await FirebaseAnalytics.enable()
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
        }
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/events */
    public async event(name: string, params?: EventParams): Promise<void> {
        this.logger.debug(this.constructor.name, "Send event " + name + " with params " + JSON.stringify(params));

        if (this.deviceHelper.isRunningOnDevice()) {
            await FirebaseAnalytics.logEvent({ name, params })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
        }
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
    public pageView(page_title?: string, page_path?: string, page_location?: string) {
        return this.event("page_view", { page_title, page_location, page_path });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions */
    public exception(description?: string, fatal?: boolean) {
        return this.event("exception", { description, fatal });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings */
    public timingComplete(name: string, value: number, event_category?: string, event_label?: string) {
        return this.event("timing_complete", { name, value, event_category, event_label });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/screens */
    public screenView(app_name: string, screen_name: string, app_id?: string, app_version?: string, app_installer_id?: string) {
        return this.event("screen_view", { app_name, screen_name, app_id, app_version, app_installer_id });
    }

    public login(method?: string) {
        return this.event("login", { method });
    }

    public signUp(method?: string) {
        return this.event("sign_up", { method });
    }

    public search(search_term?: string) {
        return this.event("search", { search_term });
    }

    public share(method?: string, content?: Content) {
        return this.event("share", { method, ...content });
    }

    public viewSearchResults(search_term?: string) {
        return this.event("view_search_results", { search_term });
    }

    public viewStrong(language: string, strongNumber: number) {
        return this.event("view_strong", { language, strongNumber });
    }

    public downloadBible(code: string) {
        return this.event("download_bible", { code });
    }

    public verseHighlightsSynchronization(newLocal: number, newRemote: number) {
        return this.event("verse_highlights_sync", { newLocal, newRemote });
    }
}
