import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { ThirdParty } from "../../models/thirdParty.model";
import { User } from "../../models/user.model";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class UserDbDao extends DbDaoBase<User> {
    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private thirdPartyDbDao: ThirdPartyDbDao) {
        super(logger);
    }

    public getCurrentUserWithThirdPartyOnly(): Promise<User> {
        return this.get(null, false)
            .then(user => {
                if (user) {
                    return this.thirdPartyDbDao.get(user.thirdParty.id, false)
                        .then(thirdParty => {
                            user.thirdParty = thirdParty;
                            return user;
                        });
                } else {
                    return null;
                }
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + User.TABLENAME + "_id"
                    + " ON " + User.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + User.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "username TEXT, "
                    + "thirdParty TEXT, "
                    + "wording TEXT,"
                    + "language TEXT,"
                    + "company TEXT,"
                    + "profile TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + User.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    public deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + User.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean): Promise<User> {
        let selectQuery = "SELECT * FROM " + User.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let user: User = this.rowToModel(data.rows[0]);

                    if (hydrate) {
                        return this.thirdPartyDbDao.get(user.thirdParty.id, hydrate)
                            .then(value => {
                                user.thirdParty = value;
                                return user;
                            });
                    } else {
                        return user;
                    }
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return User.TABLENAME;
    }

    protected rowToModel(row: any): User {
        let user = new User();
        user.id = row.id;
        user.username = row.username;
        user.thirdParty.id = row.thirdParty;
        user.wording = row.wording;
        user.language = row.language;
        user.company = JSON.parse(row.company);
        user.profile = JSON.parse(row.profile);

        return user;
    }

    public save(user: User): Promise<User> {
        return this.thirdPartyDbDao.save(user.thirdParty)
            .then((value: ThirdParty) => {
                let query = "INSERT OR REPLACE INTO " + User.TABLENAME + " (id, username, thirdParty, language, wording, company, profile) VALUES ("
                            + this.getValue(user.id)
                            + this.getValue(user.username)
                            + this.getFkValue(user.thirdParty)
                            + this.getValue(user.language)
                            + this.getValue(user.wording)
                            + this.getValueAsJsonString(user.company)
                            + this.getValueAsJsonString(user.profile, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return user;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
