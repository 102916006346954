import { EventEmitter, Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DateTime } from "luxon";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../../gyzmo-commons/helpers/network.helper";
import { DirectConnectionHttpClient } from "../../gyzmo-commons/http/directConnectionHttpClient";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";
import { OfflineModeDbDao } from "../dao/db/offlineMode.db.dao";
import { OfflineModeWsDao } from "../dao/ws/offlineMode.ws.dao";
import { OfflineModeRequestDto } from "../dto/offlineModeRequest.dto";
import { RequestMode } from "../interfaces/requestMode";
import { OfflineModeRequest } from "../models/offlineModeRequest.model";

@Injectable({
    providedIn: "root",
})
export class OfflineModeService {
    private _synchronisationLock: boolean = false;
    private _synchronisationInProgress: boolean = false;

    private offlineModeServiceEventSubject = new EventEmitter<void>();

    constructor(private logger: Logger,
                private httpClientProvider: HttpClientProvider,
                private networkHelper: NetworkHelper,
                private deviceHelper: DeviceHelper,
                private offlineModeDbDao: OfflineModeDbDao,
                private offlineModeWsDao: OfflineModeWsDao) {
        this.networkHelper.getConnectivityObservable()
            .subscribe(() => {
                this.getOfflineModeServiceEventObservable().emit();
            });
    }

    public getOfflineModeServiceEventObservable(): EventEmitter<void> {
        return this.offlineModeServiceEventSubject;
    }

    public startSynchronisationTask() {
        this.offlineModeWsDao.setServerlessWsDao(new DirectConnectionHttpClient(this.httpClientProvider));

        if (this.deviceHelper.isRunningOnDevice()) {
            this.logger.info(this.constructor.name, "Starting synchronization, mobile mode");

            this.networkHelper.getConnectivityObservable()
                .subscribe((online) => {
                    if (!online) {
                        return;
                    }

                    if (!this._synchronisationLock) {
                        this._synchronisationLock = true;

                        this.sync()
                            .then(value => {
                                this._synchronisationLock = false;
                            });
                    }
                });
        } else {
            this.logger.info(this.constructor.name, "Starting synchronization, browser mode");

            setInterval(() => {
                    if (!this._synchronisationLock) {
                        this._synchronisationLock = true;

                        this.sync()
                            .then(value => {
                                this._synchronisationLock = false;
                            });
                    }
                },
                5000);
        }
    }

    isNetworkConnected(): boolean {
        return this.networkHelper.isConnected();
    }

    isSyncing(): boolean {
        return this._synchronisationInProgress;
    }

    getPendingRequestCount(apiFilter?: string): Promise<number> {
        return this.offlineModeDbDao.getPendingRequestCount(apiFilter);
    }

    public queueRequest(mode: RequestMode, api: string, url: string, headers: any, data: any): Promise<void> {
        if (mode == RequestMode.DELETE || mode == RequestMode.PUT || mode == RequestMode.POST) {
            this.logger.info(this.constructor.name, "queueRequest", mode, url, data);

            const offlineModeRequest = new OfflineModeRequest();
            offlineModeRequest.api = api;
            offlineModeRequest.date = DateTime.now().toFormat(DATE_NODEJS_FORMAT);
            offlineModeRequest.mode = mode;
            offlineModeRequest.url = url;
            offlineModeRequest.headers = headers;
            offlineModeRequest.data = data;
            offlineModeRequest.retryCount = 0;

            return this.offlineModeDbDao.save(offlineModeRequest)
                .then(ignore => {
                    return;
                });
        } else {
            return new Promise<void>((resolve) => {
                resolve();
            });
        }
    }

    private async sync(): Promise<void> {
        let offlineModeRequests = await this.offlineModeDbDao.list();
        if (offlineModeRequests.length > 0) {
            this._synchronisationInProgress = true;
            this.getOfflineModeServiceEventObservable().emit();

            this.logger.info(this.constructor.name, "Synchronisation : " + offlineModeRequests.length + " request(s) to synchronize.");

            for (const offlineModeRequest of offlineModeRequests) {
                const offlineModeRequestDto = OfflineModeRequestDto.fromModel(offlineModeRequest);
                let success = await this.offlineModeWsDao.replayRequest(offlineModeRequestDto);

                if (success) {
                    this.logger.info(this.constructor.name, "Success, continue syncing");
                    await this.offlineModeDbDao.delete("" + offlineModeRequest.rowid);
                    this.getOfflineModeServiceEventObservable().emit();
                } else {
                    this.logger.info(this.constructor.name, "Failure, stop syncing");
                    // Stop syncing
                    break;
                }
            }

            this._synchronisationInProgress = false;
            this.getOfflineModeServiceEventObservable().emit();
        }
    }
}
