import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DateTime } from "luxon";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { Ltc } from "../../models/ltc.model";
import { ConstructionSiteDbDao } from "./constructionSite.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable()

export class LtcDbDao extends DbDaoBase<Ltc> {
    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private constructionSiteDbDao: ConstructionSiteDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao) {
        super(logger);
    }

    public getList(startDate: DateTime, endDate: DateTime,
                   hydrateMovement: boolean = false,
                   hydrateObjects: boolean = false): Promise<Ltc[]> {
        let selectQuery = "SELECT * FROM " + Ltc.TABLENAME;

        if (startDate && endDate) {
            selectQuery += " WHERE startDate >= '" + startDate.toFormat(DATE_NODEJS_FORMAT) + "' AND startDate <= '" + endDate.toFormat(DATE_NODEJS_FORMAT) + "'";
        }
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let ltcs: Ltc[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    ltcs.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                ltcs.forEach(ltc => {
                    if (hydrateObjects) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.customer.id, hydrateObjects)
                            .then(value => {
                                ltc.customer = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.driver.id, hydrateObjects)
                            .then(value => {
                                ltc.driver = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.merchant.id, hydrateObjects)
                            .then(value => {
                                ltc.merchant = value;
                            }));

                        hydratationPromises.push(this.constructionSiteDbDao.get(ltc.constructionSite.id, hydrateObjects)
                            .then(value => {
                                ltc.constructionSite = value;
                            }));
                    }
                });

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return ltcs;
                    });
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        const query = "CREATE INDEX IF NOT EXISTS idx_" + Ltc.TABLENAME + "_startDate"
                      + " ON " + Ltc.TABLENAME + "(startDate);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Ltc.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "startDate TEXT,"
                    + "orderDate TEXT,"
                    + "plannedReturnDate TEXT,"
                    + "returnDate TEXT,"
                    + "wantedDays NUMBER,"
                    + "duration NUMBER,"
                    + "cumulativeMileage NUMBER,"
                    + "includedMileage NUMBER,"
                    + "contractNumber TEXT,"
                    + "bookingCode TEXT,"
                    + "isClosed NUMBER,"
                    // Fks
                    + "customer TEXT,"
                    + "driver TEXT,"
                    + "merchant TEXT,"
                    + "constructionSite TEXT,"
                    + "movement TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Ltc.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Ltc.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Ltc> {
        let selectQuery = "SELECT * FROM " + Ltc.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let ltc: Ltc = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.customer.id, hydrate)
                            .then(value => {
                                ltc.customer = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.driver.id, hydrate)
                            .then(value => {
                                ltc.driver = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(ltc.merchant.id, hydrate)
                            .then(value => {
                                ltc.merchant = value;
                            }));
                        hydratationPromises.push(this.constructionSiteDbDao.get(ltc.constructionSite.id, hydrate)
                            .then(value => {
                                ltc.constructionSite = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return ltc;
                        });
                })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
                return null;
            });
    }

    public getTableName(): string {
        return Ltc.TABLENAME;
    }

    protected rowToModel(row: any): Ltc {
        let ltc = new Ltc();

        ltc.id = row.id;
        ltc.startDate = row.startDate;
        ltc.orderDate = row.orderDate;
        ltc.plannedReturnDate = row.plannedReturnDate;
        ltc.returnDate = row.returnDate;
        ltc.wantedDays = Number(row.wantedDays);
        ltc.duration = row.duration;
        ltc.cumulativeMileage = Number(row.cumulativeMileage);
        ltc.includedMileage = Number(row.includedMileage);
        ltc.contractNumber = row.contractNumber;
        ltc.bookingCode = row.bookingCode;

        ltc.isClosed = row.isClosed;

        // Fks
        ltc.customer.id = row.customer;
        ltc.driver.id = row.driver;
        ltc.merchant.id = row.merchant;
        ltc.movement.id = row.movement;
        ltc.constructionSite.id = row.constructionSite;

        return ltc;
    }

    public save(ltc: Ltc): Promise<Ltc> {
        let promises = [];

        promises.push(this.thirdPartyDbDao.save(ltc.customer));
        promises.push(this.thirdPartyDbDao.save(ltc.driver));
        promises.push(this.thirdPartyDbDao.save(ltc.merchant));

        return Promise.all(promises)
            .then(values => {
                let query = "INSERT OR REPLACE INTO " + Ltc.TABLENAME + " ("
                            + "id, startDate, orderDate, plannedReturnDate, returnDate,"
                            + "wantedDays, duration, cumulativeMileage, includedMileage, "
                            + "contractNumber, bookingCode, isClosed, "
                            // Fks
                            + "customer, driver, merchant, constructionSite, movement"
                            + ") VALUES ("
                            + this.getValue(ltc.id)
                            + this.getValue(ltc.startDate)
                            + this.getValue(ltc.orderDate)
                            + this.getValue(ltc.plannedReturnDate)
                            + this.getValue(ltc.returnDate)
                            + this.getValue(ltc.wantedDays)
                            + this.getValue(ltc.duration)
                            + this.getValue(ltc.cumulativeMileage)
                            + this.getValue(ltc.includedMileage)
                            + this.getValue(ltc.contractNumber)
                            + this.getValue(ltc.bookingCode)
                            + this.getValue(ltc.isClosed)
                            // Fks
                            + this.getFkValue(ltc.customer)
                            + this.getFkValue(ltc.driver)
                            + this.getFkValue(ltc.merchant)
                            + this.getFkValue(ltc.constructionSite)
                            + this.getFkValue(ltc.movement, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return ltc;
                    })
                    .catch(reason => {
                        this.logger.error(this.constructor.name, reason);
                        return null;
                    });
            });
    }
}
