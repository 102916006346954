import { Injectable } from "@angular/core";
import { WsDaoBase } from "../../../gyzmo-commons/dao/ws/base/ws.dao.base";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { HourTypeDto } from "../../dto/hourType.dto";
import { ServerConnection } from "../../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class HourTypeWsDao extends WsDaoBase<HourTypeDto> {
    public getById(serverConnection: ServerConnection, id: string): Promise<HourTypeDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: HourTypeDto): Promise<HourTypeDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<HourTypeDto[]> {
        return new Promise<HourTypeDto[]>(async (resolve, reject) => {
            let allHourKinds = [];

            let filters = ["HRS_CHAUF"];
            let subHourfilter = "HRS_CHAUF_NOR";
            for (const filter of filters) {
                let tokens = new Map<string, string>();
                tokens.set("hourKind", filter);
                tokens.set("subHourKind", subHourfilter);

                let hourKinds = await serverConnection.get(this.constructor.name, "services?hasHourKind=true&hourKind=:hourKind&subHourKind=:subHourKind", tokens)
                    .then(async response => {
                        let hourKinds: HourTypeDto[] = [];
                        (response.body as Array<any>).forEach(value => {
                            let hourKind = HourTypeDto.fromBody(value, filter);
                            hourKinds.push(hourKind);
                        });

                        for (const hourKind of hourKinds) {
                            let response = await serverConnection.get(this.constructor.name, "services/" + hourKind.id, tokens);
                            hourKind.kind = response.body.hourType.id;
                        }

                        return hourKinds;
                    })
                    .catch(reason => {
                        return [];
                    });

                allHourKinds = allHourKinds.concat(hourKinds);
            }

            resolve(allHourKinds);
        });
    }
}
