import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Attachment } from "./attachment.model";

export class AttachedDocument extends ModelBase {
    static TABLENAME = "AttachedDocuments";

    id: string = "";
    wording: string = "";
    file: string = "";

    constructor() {
        super(Attachment.TABLENAME);
    }
}
