export function isNullOrUndefined(value: any): boolean {
    if (!value) {
        return true;
    }
    return false;
}

export function isNullOrEmpty(value: string): boolean {
    if (!value || value.length <= 0) {
        return true;
    }

    return false;
}
