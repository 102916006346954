import { NgModule } from "@angular/core";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { HttpClientProvider } from "../providers/httpClientProvider";
import { AngularHttpClient } from "./angularHttpClient";
import { AngularHttpClientWithMock } from "./angularHttpClientWithMock";
import { NativeHttpClient } from "./nativeHttpClient";

@NgModule({
    imports: [],
    providers: [
        HTTP,
        HttpClientProvider,
        AngularHttpClient,
        AngularHttpClientWithMock,
        NativeHttpClient,
    ],
})
export class CommonHttpModule {
}
