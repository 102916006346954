import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { Civility, ThirdParty } from "../models/thirdParty.model";
import { AddressDto } from "./address.dto";
import { AttachmentDto } from "./attachment.dto";
import { BaseDto } from "./base/base.dto";
import { ContactDetailDto } from "./contactDetail.dto";

export class ThirdPartyDto extends BaseDto<ThirdParty> {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public civility: { id: Civility } = { id: Civility.MALE };
    public isActive: boolean;
    public mainAddress: AddressDto = null;

    public mail: ContactDetailDto = null;
    public mobile: ContactDetailDto = null;
    public phone: ContactDetailDto = null;

    public company: { id } = { id: null };

    public attachments: AttachmentDto[] = [];

    constructor() {
        super();

        this.mainAddress = new AddressDto();
        this.mail = new ContactDetailDto();
        this.mobile = new ContactDetailDto();
        this.phone = new ContactDetailDto();
        this.isActive = false;
    }

    public static fromBody(body: any): ThirdPartyDto {
        let thirdPartyDto = new ThirdPartyDto();

        thirdPartyDto.id = body.id;
        thirdPartyDto.firstName = body.firstName;
        thirdPartyDto.lastName = body.lastName;

        if (body.company) {
            thirdPartyDto.company = { id: body.company.id };
        }

        if (body.civility) {
            thirdPartyDto.civility = { id: body.civility.id };
        }

        if (body.mainAddress) {
            thirdPartyDto.mainAddress = AddressDto.fromBody(body.mainAddress);
        }

        if (body.mail) {
            thirdPartyDto.mail = ContactDetailDto.fromBody(body.mail, thirdPartyDto.id);
        }

        if (body.mobile) {
            thirdPartyDto.mobile = ContactDetailDto.fromBody(body.mobile, thirdPartyDto.id);
        }

        if (body.phone) {
            thirdPartyDto.phone = ContactDetailDto.fromBody(body.phone, thirdPartyDto.id);
        }

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                thirdPartyDto.attachments.push(AttachmentDto.fromBody(attachment, "thirdParty", thirdPartyDto.id));
            });
        }

        return thirdPartyDto;
    }

    static fromModel(thirdParty: ThirdParty) {
        let thirdPartyDto = new ThirdPartyDto();

        thirdPartyDto.id = thirdParty.id;
        thirdPartyDto.firstName = thirdParty.firstName;
        thirdPartyDto.lastName = thirdParty.lastName;
        thirdPartyDto.civility = thirdParty.civility;
        thirdPartyDto.company = thirdParty.company;
        thirdPartyDto.isActive = (thirdParty.isActive == 1);

        if (thirdParty.mainAddress) {
            thirdPartyDto.mainAddress = AddressDto.fromModel(thirdParty.mainAddress);
        }

        if (thirdParty.mail) {
            thirdPartyDto.mail = ContactDetailDto.fromModel(thirdParty.mail, thirdParty.id);
        }

        if (thirdParty.mobile) {
            thirdPartyDto.mobile = ContactDetailDto.fromModel(thirdParty.mobile, thirdParty.id);
        }

        if (thirdParty.phone) {
            thirdPartyDto.phone = ContactDetailDto.fromModel(thirdParty.phone, thirdParty.id);
        }

        if (thirdParty.attachments) {
            thirdParty.attachments.forEach(attachment => {
                thirdPartyDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return thirdPartyDto;
    }

    toModel(): ThirdParty {
        let thirdParty = new ThirdParty();

        thirdParty.id = this.id;
        thirdParty.firstName = this.firstName;
        thirdParty.lastName = this.lastName;
        thirdParty.civility = this.civility;
        thirdParty.company = this.company;
        thirdParty.isActive = this.isActive ? 1 : 0;

        thirdParty.mainAddress = this.mainAddress.toModel();

        thirdParty.mail = this.mail.toModel();
        thirdParty.mobile = this.mobile.toModel();
        thirdParty.phone = this.phone.toModel();

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                thirdParty.attachments.push(attachment.toModel());
            });
        }

        return thirdParty;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.mainAddress = this.mainAddress.toBody();
        clone.mail = this.mail.toBody();
        clone.mobile = this.mobile.toBody();
        clone.phone = this.phone.toBody();

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        // Fixer la currency
        clone["currency"] = { id: "EUR" };
        clone["wording"] = this.lastName + " " + this.firstName;

        if (this.mainAddress && isNullOrEmpty(this.mainAddress.id)) {
            delete clone.mainAddress.id;
        }

        if (isNullOrEmpty(this.id)) {
            delete clone.id;
        }

        return clone;
    }
}
