import { Injectable } from "@angular/core";
import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js/max";

@Injectable({
    providedIn: "root",
})
export class PhoneNumberHelper {
    public static parse(number: string): PhoneNumber {
        return parsePhoneNumberFromString(number, "FR");
    }
}
