import { Injectable } from "@angular/core";
import { CompanyWsDao } from "../dao/ws/company.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class CompanyService {
    constructor(private companyWsDao: CompanyWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getById(id: string) {
        return this.companyWsDao.getById(this.serversConnectionsProvider.getPrimaryServerConnection(), id);
    }
}
