import { IonicConfig } from "@ionic/core";

export const API_VERSION: number = 1;
export const MODULE: string = "10010";

export const DEFAULT_MAX_TANK_CAPACITY: number = 8;

export const TESTS_ROOT_URL = "http://localhost:4209";

export const APP_OPTIONS: IonicConfig =
    {
        // mode: 'ios', // Pour forcer en mode ios
        scrollPadding: false,
        scrollAssist: false,
    };
