import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { TimeEntryDbDao } from "../dao/db/timeEntry.db.dao";
import { TimeEntryWsDao } from "../dao/ws/timeEntry.ws.dao";
import { TimeEntryDto } from "../dto/timeEntry.dto";
import { TimeEntry } from "../models/timeEntry";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { UserService } from "./user.service";

@Injectable({
    providedIn: "root",
})
export class TimeEntryService {
    private mockCache = new Map<string, TimeEntryDto>();

    constructor(private timeEntryWsDao: TimeEntryWsDao,
                private timeEntryDbDao: TimeEntryDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private userService: UserService,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getOnlineList(ltcId: string): Promise<TimeEntryDto[]> {
        return new Promise<TimeEntryDto[]>((resolve) => {
            this.userService.getCurrentUser(false).then(user => {
                this.timeEntryWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection(), ltcId, user.thirdParty.id)
                    .then(timeEntries => {
                        for (let i = 0; i < timeEntries.length; i++) {
                            timeEntries[i] = this.updateMockCache(timeEntries[i]);
                        }

                        resolve(timeEntries);
                    });
            });
        });
    }

    public async synchronize(ltcId: string): Promise<TimeEntryDto[]> {
        let timeEntryDtos = await this.getOnlineList(ltcId);

        let promises = [];
        timeEntryDtos.forEach(timeEntryDto => {
            promises.push(this.timeEntryDbDao.save(timeEntryDto.toModel(ltcId)));
        });

        await Promise.all(promises);
        return timeEntryDtos;
    }

    public async save(timeEntry: TimeEntry): Promise<TimeEntry> {
        timeEntry.additionalQuantity = timeEntry.quantity;

        let parametersKv = await this.keyValueDbDao.get("IMOB_CLOCK_PARAMETERS");
        if (parametersKv && !isNullOrEmpty(parametersKv.value)) {
            let parameters = JSON.parse(parametersKv.value);

            if (parameters.quantityRules) {
                parameters.quantityRules.forEach(value => {
                    if (value.serviceId == timeEntry.service.id) {
                        timeEntry.additionalQuantity = value.additionalQuantity;
                    }
                });
            }
        }

        let updatedTimeEntry = await this.timeEntryWsDao.save(this.serversConnectionsProvider.getPrimaryServerConnection(), TimeEntryDto.fromModel(timeEntry));

        this.mockCache.set(updatedTimeEntry.id, updatedTimeEntry);

        return await this.timeEntryDbDao.save(updatedTimeEntry.toModel());
    }

    public clearMockCache() {
        this.mockCache = new Map<string, TimeEntryDto>();
    }

    private updateMockCache(timeEntryDto: TimeEntryDto): TimeEntryDto {
        if (!environment.testConfig) {
            return timeEntryDto;
        }

        if (this.mockCache.has(timeEntryDto.id)) {
            return this.mockCache.get(timeEntryDto.id);
        }

        return timeEntryDto;
    }
}
