import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { WsDaoBase } from "../../../gyzmo-commons/dao/ws/base/ws.dao.base";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { LtcDto } from "../../dto/ltc.dto";
import { MovementDto } from "../../dto/movement.dto";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { MovementWsDao } from "./movement.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";

@Injectable()
export class LtcWsDao extends WsDaoBase<LtcDto> {
    static WS = "long-term-contracts";

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private movementWsDao: MovementWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<LtcDto> {
        return new Promise<LtcDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, LtcWsDao.WS + "/:id", tokens)
                .then(response => {
                    let ltcDto = LtcDto.fromBody(response.body, true);
                    let promises = [];

                    if (ltcDto.movement && ltcDto.movement.id) {
                        promises.push(this.movementWsDao.getById(serverConnection, ltcDto.movement.id)
                            .then((value: MovementDto) => {
                                ltcDto.movement = value;
                            }));
                    }

                    if (ltcDto.customer && ltcDto.customer.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.customer.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.customer = value;
                            }));
                    }

                    if (ltcDto.driver && ltcDto.driver.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.driver.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.driver = value;
                            }));
                    }

                    if (ltcDto.merchant && ltcDto.merchant.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.merchant.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.merchant = value;
                            }));
                    }

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(ltcDto);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, ltcDto: LtcDto): Promise<LtcDto> {
        throw new Error("Not implemented");
    }

    public getList(serverConnection: ServerConnection, thirdPartyId: string, startDate?: DateTime, endDate?: DateTime): Promise<LtcDto[]> {
        return new Promise<LtcDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = LtcWsDao.WS + "?_limit=NOLIMIT&filterFor=driverHours&status=3";

            if (startDate && endDate) {
                url += "&startDate=" + startDate.toFormat(DATE_NODEJS_FORMAT) + "&endDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            }

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    let ltcDtos: LtcDto[] = [];
                    if (response.body) {
                        (response.body as Array<any>).forEach(value => {

                            let ltcDto = LtcDto.fromBody(value, false);

                            let promises = [];
                            if (ltcDto.customer && ltcDto.customer.id) {
                                promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.customer.id)
                                    .then((value: ThirdPartyDto) => {
                                        ltcDto.customer = value;
                                    }));
                            }

                            ltcDtos.push(ltcDto);

                            return Promise.all(promises)
                                .then(ignored => {
                                    return ltcDtos;
                                });
                        });
                    }

                    resolve(ltcDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
