import { NgModule } from "@angular/core";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";

@NgModule({
    imports: [],
    providers: [
        LocationAccuracy,
        Network,
        PhotoViewer,
        SocialSharing,
    ],
})
export class CommonNativeModule {
}
