import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { ServiceType } from "src/core/models/serviceType";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";

@Injectable({
    providedIn: "root",
})
export class ServiceTypeDbDao extends DbDaoBase<ServiceType> {
    constructor(
        logger: Logger,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public list(): Promise<ServiceType[]> {
        let selectQuery = "SELECT * FROM " + ServiceType.TABLENAME + ";";

        return this.listByQuery(selectQuery);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ServiceType.TABLENAME + "_id"
                    + " ON " + ServiceType.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ServiceType.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY, "
                    + "wording TEXT "
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ServiceType.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ServiceType.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<ServiceType> {
        let selectQuery = "SELECT * FROM " + ServiceType.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let returnCheckStatus = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return returnCheckStatus;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return ServiceType.TABLENAME;
    }

    protected rowToModel(row: any): ServiceType {
        let hourType = new ServiceType();

        hourType.id = row.id;
        hourType.wording = row.wording;

        return hourType;
    }

    public save(serviceType: ServiceType): Promise<ServiceType> {
        let promises = [];

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + ServiceType.TABLENAME + " ("
                            + "id, "
                            + "wording "
                            + ") VALUES ("
                            + this.getValue(serviceType.id)
                            + this.getValue(serviceType.wording, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return serviceType;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }

    private listByQuery(query: string): Promise<ServiceType[]> {
        return this.sqlProvider.query(query)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let serviceTypes: ServiceType[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    serviceTypes.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return serviceTypes;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
