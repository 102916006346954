import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { TimeEntry } from "../models/timeEntry";
import { BaseDto } from "./base/base.dto";

export class TimeEntryDto extends BaseDto<TimeEntry> {
    public id: string = "";
    public visitDate: DateTime = null;
    public quantity: number = 0;
    public additionalQuantity: number = 0;
    public contractId: string = "";

    public driver: { id: string } = { id: null };
    public service: { id: string } = { id: null };
    public status: { id: string } = { id: null };

    static fromBody(body: any, thirdPartId: string): TimeEntryDto {
        let timeEntryDto = new TimeEntryDto();

        timeEntryDto.id = body.id;
        timeEntryDto.visitDate = DateHelper.tryFromFormat(body.visitDate, DATE_NODEJS_FORMAT);
        timeEntryDto.quantity = body.quantity;
        timeEntryDto.additionalQuantity = body.additionalQuantity;
        timeEntryDto.contractId = body.contractId;

        if (body.service) {
            timeEntryDto.service.id = body.service.id;
        }

        if (body.status) {
            timeEntryDto.status.id = body.status.id;
        }

        timeEntryDto.driver.id = thirdPartId;

        return timeEntryDto;
    }

    static fromModel(timeEntry: TimeEntry) {
        let timeEntryDto = new TimeEntryDto();

        timeEntryDto.id = timeEntry.id;
        timeEntryDto.visitDate = DateTime.fromISO(timeEntry.visitDate);
        timeEntryDto.quantity = timeEntry.quantity;
        timeEntryDto.additionalQuantity = timeEntry.additionalQuantity;
        timeEntryDto.contractId = timeEntry.contractId;

        timeEntryDto.service = timeEntry.service;
        timeEntryDto.status = timeEntry.status;
        timeEntryDto.driver = timeEntry.driver;

        return timeEntryDto;
    }

    public toModel(ltcId? : string): TimeEntry {
        let timeEntry = new TimeEntry();

        timeEntry.id = this.id;
        timeEntry.visitDate = DateHelper.tryToISO(this.visitDate);
        timeEntry.quantity = this.quantity;
        timeEntry.additionalQuantity = this.additionalQuantity;
        timeEntry.contractId = ltcId;

        timeEntry.service = this.service;
        timeEntry.status = this.status;
        timeEntry.driver = this.driver;

        return timeEntry;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.visitDate = DateHelper.tryToFormat(this.visitDate, DATE_NODEJS_FORMAT);

        return clone;
    }
}
