import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DateTime } from "luxon";
import { MonthNumbers } from "luxon/src/datetime";
import { DateHelper } from "src/gyzmo-commons/helpers/date.helper";
import { DateProvider } from "../../../interfaces/dateProvider";

export interface CalendarConfig {
    titleLabel?: string;
    btnCloseSetInReverse?: boolean; // default false

    monthLabels?: string[];
    dayLabels?: string[];
    date?: DateTime;
    fromDate?: DateTime;
    toDate?: DateTime;
    validDates?: DateTime[];
    datesWithContent?: DateTime[];
}

@Component({
    selector: "calendar",
    templateUrl: "calendar.component.html",
    styleUrls: ["calendar.component.scss"],
})
export class CalendarComponent implements OnChanges {
    @Input() config: CalendarConfig;
    @Input() showDebugInfo = false;
    @Output() dateSelectedEvent: EventEmitter<DateTime> = new EventEmitter();

    DateHelper = DateHelper;

    mainConfig: CalendarConfig;

    showView = "calendar";
    weeks: Array<Array<DateTime>>;
    years: Array<number>;

    yearSelected: number;
    monthSelected: number;

    daySelected: DateTime;
    dayHighlighted: DateTime;

    startYear: number;
    endYear: number;

    constructor(public dateProvider: DateProvider) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.mainConfig = this.mergeConfig(this.config);
        if (this.mainConfig) {
            this.yearSelected = this.mainConfig.date ? this.mainConfig.date.year : this.dateProvider.now().year;
            this.monthSelected = this.mainConfig.date ? this.mainConfig.date.month : this.dateProvider.now().month;
            this.initOptions();
            this.createCalendarWeeks();
        }
    }

    mergeConfig(config: CalendarConfig): CalendarConfig {
        if (config) {
            const objConfig: CalendarConfig = {};
            objConfig.titleLabel = config.titleLabel ? config.titleLabel : "Date";
            objConfig.btnCloseSetInReverse = config.btnCloseSetInReverse ? config.btnCloseSetInReverse : false;

            let monthsDate = this.dateProvider.now().startOf("year");
            let monthsLabels: string[] = [];
            for (let i = 0; i < 12; i++) {
                monthsLabels.push(monthsDate.toFormat("MMMM"));
                monthsDate = monthsDate.plus({ month: 1 });
            }

            let daysDate = this.dateProvider.now().startOf("month").startOf("week");
            let daysLabels: string[] = [];
            for (let i = 0; i < 7; i++) {
                daysLabels.push(daysDate.toFormat("ccc"));
                daysDate = daysDate.plus({ day: 1 });
            }

            objConfig.monthLabels = config.monthLabels ? config.monthLabels : monthsLabels;
            objConfig.dayLabels = config.dayLabels ? config.dayLabels : daysLabels;
            objConfig.date = config.date ? config.date.startOf("day") : null;
            objConfig.fromDate = config.fromDate ? config.fromDate.startOf("day") : null;
            objConfig.toDate = config.toDate ? config.toDate.startOf("day") : null;
            objConfig.validDates = config.validDates ? config.validDates : [];
            objConfig.datesWithContent = config.datesWithContent ? config.datesWithContent : [];

            return objConfig;
        }

        return null;
    }

    initOptions() {
        if (this.mainConfig) {
            if (this.mainConfig.date && this.mainConfig.fromDate && this.mainConfig.date < this.mainConfig.fromDate) {
                throw new Error("Invalid date input. date must be same or greater than fromDate");
            }

            if (this.mainConfig.date && this.mainConfig.toDate && this.mainConfig.toDate < this.mainConfig.date) {
                throw new Error("Invalid date input. date must be same or lesser than toDate");
            }

            if (this.mainConfig.toDate && this.mainConfig.fromDate && this.mainConfig.fromDate > this.mainConfig.toDate) {
                throw new Error("Invalid date input. from date must be lesser than or equal to toDate");
            }

            this.yearSelected = this.mainConfig.date ? this.mainConfig.date.year : this.mainConfig.toDate ? this.mainConfig.toDate.year : this.dateProvider.now().year;
            this.monthSelected = this.mainConfig.date ? this.mainConfig.date.month : this.mainConfig.toDate ? this.mainConfig.toDate.month : this.dateProvider.now().month;
            this.dayHighlighted = this.mainConfig.date ? this.mainConfig.date : this.mainConfig.toDate ? this.mainConfig.toDate : this.dateProvider.now();

            if (this.mainConfig.date) {
                this.daySelected = this.dayHighlighted;
            }
        }
    }

    createCalendarWeeks() {
        this.weeks = this.generateCalendarWeeks(DateTime.local(this.yearSelected, this.monthSelected, 1));
    }

    hasPrevious(): boolean {
        if (!this.mainConfig.fromDate) {
            return true;
        }

        let currentMontDate = DateTime.local(this.yearSelected, this.monthSelected, 1);
        let previousMonthDate = currentMontDate.minus({ month: 1 }).endOf("month");

        // The last day of previous month should be greater than or equal to fromDate
        return previousMonthDate >= this.mainConfig.fromDate;
    }

    hasNext(): boolean {
        if (!this.mainConfig.toDate) {
            return true;
        }

        let currentMontDate = DateTime.local(this.yearSelected, this.monthSelected, 1);
        let nextMonthDate = currentMontDate.plus({ month: 1 }).startOf("month");

        // The last day of previous month should be greater than or equal to fromDate
        return nextMonthDate <= this.mainConfig.toDate;
    }

    previous() {
        if (this.monthSelected === 1) {
            this.monthSelected = 12;
            this.yearSelected--;
        } else {
            this.monthSelected--;
        }

        this.createCalendarWeeks();
    }

    next() {
        if (this.monthSelected === 12) {
            this.monthSelected = 1;
            this.yearSelected++;
        } else {
            this.monthSelected++;
        }

        this.createCalendarWeeks();
    }

    async confirmDay(day: DateTime) {
        if (this.dateSelectedEvent) {
            this.dateSelectedEvent.emit(day);
        }
    }

    selectDay(day: DateTime) {
        if (!this.isValidDay(day) || !this.isOneOfTheValidDates(day)) {
            return;
        }

        this.daySelected = day;
        this.confirmDay(day);
    }

    showMonthView() {
        this.showView = "month";
    }

    hasYearSelection() {
        if (!this.mainConfig.toDate || !this.mainConfig.fromDate) {
            return true;
        }

        return this.mainConfig.toDate.year !== this.mainConfig.fromDate.year;
    }

    showYearView() {
        this.showView = "year";
        let startYear = this.yearSelected - 10;
        if (startYear % 10 !== 0) {
            startYear = startYear - (startYear % 10);
        }
        const endYear = startYear + 19;

        this.startYear = startYear;
        this.endYear = endYear;

        this.generateYears();
    }

    generateYears() {
        if (this.mainConfig.fromDate && this.startYear < this.mainConfig.fromDate.year) {
            this.startYear = this.mainConfig.fromDate.year;
        }

        if (this.mainConfig.toDate && this.endYear > this.mainConfig.toDate.year) {
            this.endYear = this.mainConfig.toDate.year;
        }

        this.years = [];
        for (let i = this.startYear; i <= this.endYear; i++) {
            this.years.push(i);
        }
    }

    showPreviousYears() {
        this.endYear = this.startYear - 1;
        this.startYear = this.endYear - 19;
        this.generateYears();
    }

    showNextYears() {
        this.startYear = this.endYear + 1;
        this.endYear = this.startYear + 19;
        this.generateYears();
    }

    hasPreviousYears() {
        if (!this.mainConfig.fromDate) {
            return true;
        }

        return this.startYear > this.mainConfig.fromDate.year;
    }

    hasNextYears() {
        if (!this.mainConfig.toDate) {
            return true;
        }

        return this.endYear < this.mainConfig.toDate.year;
    }

    selectMonth(month: number) {
        let monthNumber = month as MonthNumbers;

        if (!this.isValidMonth(month)) {
            return;
        }

        this.monthSelected = monthNumber;
        this.createCalendarWeeks();
        setTimeout(() => {
            this.showView = "calendar";
        }, 200);
    }

    selectYear(year: number) {
        this.yearSelected = year;
        this.createCalendarWeeks();
        setTimeout(() => {
            this.showView = "calendar";
        }, 200);
    }

    resetView() {
        this.showView = "calendar";
    }

    generateCalendarWeeks(forDay: DateTime): Array<Array<DateTime>> {
        const weeks: Array<any> = [];
        const firstDayOfMonth: DateTime = forDay.startOf("month");
        const lastDayOfMonth: DateTime = forDay.endOf("month");
        const firstDayOfWeekOfFirstDayOfMonth: DateTime = firstDayOfMonth.startOf("week");
        const numOfWeeks: number = lastDayOfMonth.diff(firstDayOfWeekOfFirstDayOfMonth, "days").days / 7;
        
        let activeDay: DateTime = firstDayOfWeekOfFirstDayOfMonth;
        for (let week = 0; week < numOfWeeks; week++) {
            const days = [];

            for (let day = 0; day < 7; day++) {
                days.push(activeDay);
                activeDay = activeDay.plus({ days: 1 });
            }

            weeks.push(days);
        }

        return weeks;
    }

    isValidDay(day: DateTime) {
        if (!this.mainConfig.toDate && !this.mainConfig.fromDate) {
            return true;
        }

        let startOfDay = day.startOf("day");

        if (this.mainConfig.toDate && this.mainConfig.fromDate) {
            return startOfDay >= this.mainConfig.fromDate && startOfDay <= this.mainConfig.toDate;
        }

        if (this.mainConfig.toDate) {
            return startOfDay <= this.mainConfig.toDate;
        }

        if (this.mainConfig.fromDate) {
            return startOfDay >= this.mainConfig.fromDate;
        }
    }

    isOneOfTheValidDates(day: DateTime) {
        if (this.mainConfig.validDates && this.mainConfig.validDates.length) {
            const length = this.mainConfig.validDates.filter(validDate => {
                return DateHelper.isSameDay(day, validDate);
            }).length;
            return length > 0;
        }

        return true;
    }

    isValidMonth(month: number) {
        if (this.mainConfig.toDate && this.mainConfig.toDate.year !== this.yearSelected && this.mainConfig.fromDate && this.mainConfig.fromDate.year !== this.yearSelected) {
            return true;
        }

        if (!this.mainConfig.toDate && !this.mainConfig.fromDate) {
            return true;
        }

        let firstDayOfMonth = DateTime.local(this.yearSelected, month, 1).startOf("month");

        if (this.mainConfig.fromDate && !this.mainConfig.toDate) {
            return firstDayOfMonth >= this.mainConfig.fromDate.startOf("month");
        }

        if (this.mainConfig.toDate && !this.mainConfig.fromDate) {
            return firstDayOfMonth <= this.mainConfig.toDate.startOf("month");
        }

        return firstDayOfMonth >= this.mainConfig.fromDate.startOf("month")
               && firstDayOfMonth <= this.mainConfig.toDate.startOf("month");
    }

    // End of styles
    public isInCalendar(day: DateTime) {
        return this.isValidDay(day);
    }

    public getHasContent(day: DateTime) {
        return this.mainConfig.datesWithContent.filter(date => {
            return DateHelper.isSameDay(day, date);
        }).length > 0;
    }
}
