import { Injectable } from "@angular/core";
import { HTTP, HTTPResponse } from "@awesome-cordova-plugins/http/ngx";
import { Logger } from "ionic-logging-service";
import { isNullOrEmpty } from "../helpers/null.helper";
import { IHttpClient } from "../interfaces/IHttpClient";
import { HttpResponse } from "./httpResponse";

@Injectable({
    providedIn: "root",
})
export class NativeHttpClient implements IHttpClient {
    constructor(private logger: Logger,
                private httpClient: HTTP) {
        httpClient.setDataSerializer("json");
        httpClient.setServerTrustMode("nocheck");
    }

    private static headersToMap(headers: { [key: string]: string; }) {
        let result = new Map<string, string>();

        for (let key in headers) {
            result.set(key, headers[key]);
        }

        return result;
    }

    private static removeBom(string) {
        if (typeof string !== "string") {
            throw new TypeError(`Expected a string, got ${ typeof string }`);
        }

        // Catches EFBBBF (UTF-8 BOM) because the buffer-to-string
        // conversion translates it to FEFF (UTF-16 BOM)
        if (string.charCodeAt(0) === 0xFEFF) {
            return string.slice(1);
        }

        return string;
    }

    private static getHttpResponse(response: HTTPResponse) {
        let bodyData = NativeHttpClient.removeBom(response.data);
        let data = null;
        if (!isNullOrEmpty(bodyData)) {
            data = JSON.parse(bodyData);
        }

        return new HttpResponse(response.status, data, NativeHttpClient.headersToMap(response.headers));
    }

    public async get(url: string, headers: {}): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService get " + url);

        let response = await this.httpClient.get(url,
            {},
            headers);

        return NativeHttpClient.getHttpResponse(response);
    }

    public async post(url: string, headers: {}, data: any): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService post " + url);

        let response = await this.httpClient.post(url,
            data,
            headers);

        return NativeHttpClient.getHttpResponse(response);
    }

    public async put(url: string, headers: {}, data: any): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService put " + url);

        let response = await this.httpClient.put(url,
            data,
            headers);

        return NativeHttpClient.getHttpResponse(response);
    }

    public async delete(url: string, headers: {}): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService delete " + url);

        let response = await this.httpClient.delete(url,
            {},
            headers);

        return NativeHttpClient.getHttpResponse(response);
    }
}
