import { Injectable } from "@angular/core";
import { ServiceTypeDbDao } from "../dao/db/serviceType.db.dao";
import { ServiceTypeWsDao } from "../dao/ws/serviceType.ws.dao";
import { ServiceTypeDto } from "../dto/serviceType.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class ServiceTypeService {
    constructor(private serviceTypeWsDao: ServiceTypeWsDao,
                private serviceTypeDbDao: ServiceTypeDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getDbList(): Promise<ServiceTypeDto[]> {
        return this.serviceTypeDbDao.list()
            .then(values => {
                let result = [];

                values.forEach(value => {
                    result.push(ServiceTypeDto.fromModel(value));
                });

                return result;
            });
    }

    public getById(id: string): Promise<ServiceTypeDto> {
        return this.serviceTypeDbDao.get(id)
            .then(value => {
                return ServiceTypeDto.fromModel(value);
            });
    }

    public synchronize(): Promise<void> {
        return this.serviceTypeWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(hourTypeDtos => {
                let promises = [];

                hourTypeDtos.forEach(hourTypeDto => {
                    promises.push(this.serviceTypeDbDao.save(hourTypeDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }
}
