import { Component, Input, OnInit } from "@angular/core";
import { PhoneNumber } from "libphonenumber-js";
import { ThirdPartyDto } from "../../../core/dto/thirdParty.dto";
import { AddressHelper } from "../../../core/helpers/address.helper";
import { PhoneNumberHelper } from "../../../core/helpers/phoneNumber.helper";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";

@Component({
    selector: "customer-card",
    templateUrl: "customer-card.component.html",
    styleUrls: ["customer-card.component.scss"],
})
export class CustomerCardComponent implements OnInit {
    @Input() customer: ThirdPartyDto;
    @Input() collapsed: boolean = false;

    addressHelper = AddressHelper;

    public mobileNumber: PhoneNumber = null;
    public phoneNumber: PhoneNumber = null;

    ngOnInit() {
        this.mobileNumber = this.getMobilePhoneContact();
        this.phoneNumber = this.getPhoneContact();
    }

    public call(phoneNumber: PhoneNumber) {
        window.open("tel:" + phoneNumber.number);
    }

    public text(phoneNumber: PhoneNumber) {
        window.open("sms:" + phoneNumber.number);
    }

    private getPhoneContact(): PhoneNumber {
        if (this.customer.phone && !isNullOrEmpty(this.customer.phone.data)) {
            return PhoneNumberHelper.parse(this.customer.phone.data);
        }
        return null;
    }

    private getMobilePhoneContact(): PhoneNumber {
        if (this.customer.mobile && !isNullOrEmpty(this.customer.mobile.data)) {
            return PhoneNumberHelper.parse(this.customer.mobile.data);
        }

        if (this.customer.phone && !isNullOrEmpty(this.customer.phone.data)) {
            return PhoneNumberHelper.parse(this.customer.phone.data);
        }

        return null;
    }
}
