import { AddressDto } from "../dto/address.dto";

export class AddressHelper {
    public static formatAddress(address: AddressDto) {
        let result = "";

        if (address.address1) {
            result += address.address1 + "\n";
        }
        if (address.address2) {
            result += address.address2 + "\n";
        }
        if (address.address3) {
            result += address.address3 + "\n";
        }
        if (address.zipCode || address.city) {
            result += address.zipCode + " " + address.city;
        }

        return result;
    }

    public static formatAddressForHtml(address: AddressDto) {
        let result = "";

        if (address.address1) {
            result += address.address1 + "<br />";
        }
        if (address.address2) {
            result += address.address2 + "<br />";
        }
        if (address.address3) {
            result += address.address3 + "<br />";
        }
        if (address.zipCode || address.city) {
            result += address.zipCode + " " + address.city;
        }

        return result;
    }
}
