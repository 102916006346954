import { Component, Input, OnInit } from "@angular/core";
import { PhoneNumber } from "libphonenumber-js";
import { ThirdPartyDto } from "../../../core/dto/thirdParty.dto";
import { PhoneNumberHelper } from "../../../core/helpers/phoneNumber.helper";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";

export enum ContactType {
    ConstructionSiteManager,
    WorksManager,
    Prescriber,
    Customer,
}

@Component({
    selector: "contact-col",
    templateUrl: "contact-col.component.html",
    styleUrls: ["contact-col.component.scss"],
})
export class ContactColComponent implements OnInit {
    @Input() contact: ThirdPartyDto;
    @Input() contactType: ContactType;

    public ContactType = ContactType;
    public mobileNumber: PhoneNumber = null;
    public phoneNumber: PhoneNumber = null;

    ngOnInit() {
        this.mobileNumber = this.getMobilePhoneContact();
        this.phoneNumber = this.getPhoneContact();
    }

    public call(phoneNumber: PhoneNumber) {
        window.open("tel:" + phoneNumber.number);
    }

    public text(phoneNumber: PhoneNumber) {
        window.open("sms:" + phoneNumber.number);
    }

    private getPhoneContact(): PhoneNumber {
        if (this.contact) {
            if (this.contact.phone && !isNullOrEmpty(this.contact.phone.data)) {
                return PhoneNumberHelper.parse(this.contact.phone.data);
            }
        }
        return null;
    }

    private getMobilePhoneContact(): PhoneNumber {
        if (this.contact) {
            if (this.contact.mobile && !isNullOrEmpty(this.contact.mobile.data)) {
                return PhoneNumberHelper.parse(this.contact.mobile.data);
            }

            if (this.contact.phone && !isNullOrEmpty(this.contact.phone.data)) {
                return PhoneNumberHelper.parse(this.contact.phone.data);
            }
        }
        return null;
    }
}
