import { Component, Input, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { DateTime } from "luxon";
import { CalendarConfig } from "../calendar/calendar.component";

@Component({
    selector: "date-picker-popover",
    templateUrl: "date-picker-popover.component.html",
    styleUrls: ["date-picker-popover.component.scss"],
})
export class DatePickerPopoverComponent {
    @Output() config: CalendarConfig;
    @Input() showDebugInfo = false;

    constructor(private popoverController: PopoverController) {
    }

    async confirmDay(day: DateTime) {
        const modal = await this.popoverController.getTop();
        await modal.dismiss({ date: day });
    }
}
