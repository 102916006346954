import { NgModule } from "@angular/core";
import { CommonHttpModule } from "../../gyzmo-commons/http/common.http.module";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { HttpErrorHandler } from "./httpErrorHandler";

@NgModule({
    imports: [
        CommonHttpModule,
    ],
    providers: [
        HttpErrorHandler,
        ServersConnectionsProvider,
    ],
})
export class HttpModule {
}
