import { NgModule } from "@angular/core";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { CompanyWsDao } from "./company.ws.dao";
import { ContactDetailsWsDao } from "./contactDetails.ws.dao";
import { HourTypeWsDao } from "./hourType.ws.dao";
import { LtcWsDao } from "./ltc.ws.dao";
import { MovementWsDao } from "./movement.ws.dao";
import { OfflineModeWsDao } from "./offlineMode.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { TimeEntryWsDao } from "./timeEntry.ws.dao";
import { UserWsDao } from "./user.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@NgModule({
    imports: [],
    providers: [
        AttachmentWsDao,
        CompanyWsDao,
        ContactDetailsWsDao,
        HourTypeWsDao,
        LtcWsDao,
        MovementWsDao,
        OfflineModeWsDao,
        ThirdPartyWsDao,
        TimeEntryWsDao,
        UserWsDao,
        ZoomWsDao,
    ],
})
export class WsDaoModule {
}
