import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";

@Injectable({
    providedIn: "root",
})
export class NavParamsProvider {
    params: Map<string, any>;

    constructor(private logger: Logger) {
        this.params = new Map<string, any>();
    }

    public clearParams(): void {
        this.logger.debug(this.constructor.name, "clearParams");
        this.params.clear();
    }

    public hasParam(name: string): boolean {
        return this.params.has(name);
    }

    public setParam(name: string, value: any): void {
        this.logger.debug(this.constructor.name, "setParam : " + name + "=" + JSON.stringify(value));
        this.clearParams();
        this.params.set(name, value);
    }

    public addParam(name: string, value: any): void {
        this.logger.debug(this.constructor.name, "addParam : " + name + "=" + JSON.stringify(value));
        this.params.set(name, value);
    }

    public getParam(name: string) {
        return this.params.get(name);
    }

    setParams(params: any) {
        Object.keys(params).forEach(key => {
            this.addParam(key, params[key]);
        });
    }
}
