import { NgModule } from "@angular/core";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { CommonHelperModule } from "../helpers/common.helper.module";
import { AppSqlProvider } from "./app.sql.provider";
import { Database } from "./database";
import { DatabaseEventService } from "./databaseEvent.service";

@NgModule({
    imports: [CommonHelperModule],
    providers: [
        SQLite,
        Database,
        DatabaseEventService,
        AppSqlProvider,
    ],
})
export class CommonPersistenceModule {
}
