import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { NavControllerExtended } from "../gyzmo-commons/helpers/navControllerExtended";
import { NavParamsProvider } from "../gyzmo-commons/providers/navParams.provider";

const routes: Routes = [
    {
        path: "about", loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "cgu", loadChildren: () => import("./account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "connection", loadChildren: () => import("./account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "indicators", loadChildren: () => import("./indicators/indicators.module").then(m => m.IndicatorsModule),
    },
    {
        path: "contract-search", loadChildren: () => import("./contract-search/contract-search.module").then(m => m.ContractSearchModule),
    },
    {
        path: "contract-details", loadChildren: () => import("./contract-details/contract-details.module").then(m => m.ContractDetailsModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "enabled",
            useHash: true,
        }),
    ],
    providers: [
        NavControllerExtended,
        NavParamsProvider,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
