import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { MovementWsDao } from "../../../core/dao/ws/movement.ws.dao";
import { ThirdPartyWsDao } from "../../../core/dao/ws/thirdParty.ws.dao";
import { LtcService } from "../../../core/services/ltc.service";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { ConstructionSiteCardModule } from "../construction-site-card/construction-site-card.module";
import { ContractCardModule } from "../contract-card/contract-card.module";
import { ContractResultComponentModule } from "../contract-result/contract-result.module";
import { CustomerCardComponentModule } from "../customer-card/customer-card.module";
import { ContractSearchComponent } from "./contract-search.component";

@NgModule(
    {
        declarations: [
            ContractSearchComponent,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            PipesModule,
            TranslateModule,
            ContractResultComponentModule,
            ConstructionSiteCardModule,
            CustomerCardComponentModule,
            ContractCardModule,
        ],
        entryComponents: [
            ContractSearchComponent,
        ],
        providers: [
            MovementWsDao,
            ThirdPartyWsDao,
            MovementWsDao,
            LtcService,
        ],
        exports: [
            ContractSearchComponent,
        ],
    })

export class ContractSearchModalModule {
}
