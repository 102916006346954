import { NgModule } from "@angular/core";
import { CacheDbDao } from "./cache.db.dao";
import { KeyValueDbDao } from "./keyValue.db.dao";
import { VersionDbDao } from "./version.db.dao";

@NgModule({
    imports: [],
    providers: [
        CacheDbDao,
        KeyValueDbDao,
        VersionDbDao,
    ],
})
export class CommonDbDaoModule {
}
