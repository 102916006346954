import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { LaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { ContactColModule } from "../contact-col/contact-col.module";
import { ConstructionSiteCardComponent } from "./construction-site-card.component";

@NgModule(
    {
        declarations: [
            ConstructionSiteCardComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            HttpClientModule,
            TranslateModule,
            PipesModule,
            ContactColModule,
        ],
        exports: [
            ConstructionSiteCardComponent,
        ],
        providers: [
            LaunchNavigator,
        ],
    })
export class ConstructionSiteCardModule {
}
