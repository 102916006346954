import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { ThirdParty } from "./thirdParty.model";

export class User extends ModelBase {
    static TABLENAME = "Users";

    public id: string = "";
    public username: string = "";
    public thirdParty: ThirdParty = null;
    public language: string = "";
    public wording: string = "";
    public password: string = "";
    public company: { id: string } = { id: null };
    public profile: { id: string } = { id: null };

    constructor() {
        super(User.TABLENAME);

        this.thirdParty = new ThirdParty();
    }
}
