import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Ltc } from "../models/ltc.model";
import { BaseDto } from "./base/base.dto";
import { ConstructionSiteDto } from "./constructionSite.dto";
import { MovementDto } from "./movement.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class LtcDto extends BaseDto<Ltc> {
    id = "";
    startDate: DateTime = null;
    plannedReturnDate: DateTime = null;
    orderDate: DateTime = null;
    returnDate: DateTime = null;
    wantedDays: number = 0;
    duration: number = 0;
    cumulativeMileage: number = 0;
    includedMileage: number = 0;
    contractNumber: string = "";
    bookingCode: string = "";
    isClosed: boolean = false;
    // Fks
    customer: ThirdPartyDto;
    driver: ThirdPartyDto;
    merchant: ThirdPartyDto;

    constructionSite: ConstructionSiteDto;
    movement: MovementDto;

    public isStillDownloading: boolean = false;

    constructor() {
        super();
        this.customer = new ThirdPartyDto();
        this.driver = new ThirdPartyDto();
        this.merchant = new ThirdPartyDto();

        this.constructionSite = new ConstructionSiteDto();

        this.movement = new MovementDto();
    }

    public static fromBody(body: any, isStillDownloading: boolean): LtcDto {
        let ltcDto = new LtcDto();

        ltcDto.id = body.id;
        ltcDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        ltcDto.orderDate = DateHelper.tryFromFormat(body.orderDate, DATE_NODEJS_FORMAT);
        ltcDto.plannedReturnDate = DateHelper.tryFromFormat(body.plannedReturnDate, DATE_NODEJS_FORMAT);
        ltcDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        ltcDto.wantedDays = body.wantedDays;
        ltcDto.duration = body.duration;
        ltcDto.cumulativeMileage = body.cumulativeMileage;
        ltcDto.includedMileage = body.includedMileage;
        ltcDto.contractNumber = body.contractNumber;
        ltcDto.bookingCode = body.bookingCode;
        ltcDto.isClosed = body.isClosed;

        ltcDto.isStillDownloading = isStillDownloading;

        if (body.customer) {
            ltcDto.customer = ThirdPartyDto.fromBody(body.customer);
        }
        if (body.driver) {
            ltcDto.driver = ThirdPartyDto.fromBody(body.driver);
        }
        if (body.merchant) {
            ltcDto.merchant = ThirdPartyDto.fromBody(body.merchant);
        }

        if (body.movement) {
            ltcDto.movement = MovementDto.fromBody(body.movement);
        }

        if (body.constructionSite) {
            ltcDto.constructionSite = ConstructionSiteDto.fromBody(body.constructionSite);
        }

        return ltcDto;
    }

    static fromModel(ltc: Ltc, isStillDownloading: boolean): LtcDto {
        let ltcDto = new LtcDto();

        ltcDto.id = ltc.id;
        ltcDto.startDate = DateTime.fromISO(ltc.startDate);
        ltcDto.orderDate = DateTime.fromISO(ltc.orderDate);
        ltcDto.plannedReturnDate = DateTime.fromISO(ltc.plannedReturnDate);
        ltcDto.returnDate = DateTime.fromISO(ltc.returnDate);
        ltcDto.wantedDays = ltc.wantedDays;
        ltcDto.duration = ltc.duration;
        ltcDto.cumulativeMileage = ltc.cumulativeMileage;
        ltcDto.includedMileage = ltc.includedMileage;
        ltcDto.contractNumber = ltc.contractNumber;
        ltcDto.bookingCode = ltc.bookingCode;

        ltcDto.isClosed = (ltc.isClosed == 1);

        ltcDto.isStillDownloading = isStillDownloading;

        // Fks
        if (ltc.customer) {
            ltcDto.customer = ThirdPartyDto.fromModel(ltc.customer);
        }
        if (ltc.driver) {
            ltcDto.driver = ThirdPartyDto.fromModel(ltc.driver);
        }
        if (ltc.merchant) {
            ltcDto.merchant = ThirdPartyDto.fromModel(ltc.merchant);
        }

        if (ltc.movement) {
            ltcDto.movement = MovementDto.fromModel(ltc.movement);
        }

        if (ltc.constructionSite) {
            ltcDto.constructionSite = ConstructionSiteDto.fromModel(ltc.constructionSite);
        }

        return ltcDto;
    }

    public toModel(): Ltc {
        let ltc = new Ltc();

        ltc.id = this.id;
        ltc.startDate = DateHelper.tryToISO(this.startDate);
        ltc.orderDate = DateHelper.tryToISO(this.orderDate);
        ltc.plannedReturnDate = DateHelper.tryToISO(this.plannedReturnDate);
        ltc.returnDate = DateHelper.tryToISO(this.returnDate);
        ltc.wantedDays = this.wantedDays;
        ltc.duration = this.duration;
        ltc.cumulativeMileage = this.cumulativeMileage;
        ltc.includedMileage = this.includedMileage;
        ltc.contractNumber = this.contractNumber;
        ltc.bookingCode = this.bookingCode;

        ltc.isClosed = (this.isClosed ? 1 : 0);

        // Fks
        if (this.customer) {
            ltc.customer = this.customer.toModel();
        }
        if (this.driver) {
            ltc.driver = this.driver.toModel();
        }
        if (this.merchant) {
            ltc.merchant = this.merchant.toModel();
        }

        if (this.movement) {
            ltc.movement = this.movement.toModel();
        }

        if (this.constructionSite) {
            ltc.constructionSite = this.constructionSite.toModel();
        }

        return ltc;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.orderDate = DateHelper.tryToFormat(this.orderDate, DATE_NODEJS_FORMAT);
        clone.plannedReturnDate = DateHelper.tryToFormat(this.plannedReturnDate, DATE_NODEJS_FORMAT);
        clone.returnDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);

        clone.customer = this.customer.toBody();
        clone.driver = this.driver.toBody();
        clone.merchant = this.merchant.toBody();
        clone.movement = this.movement.toBody();
        clone.constructionSite = this.constructionSite.toBody();

        return clone;
    }
}
