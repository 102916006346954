import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { ThirdParty } from "./thirdParty.model";

export class Movement extends ModelBase {
    static TABLENAME = "Movements";

    id: string = "";
    documentNumber: string = "";
    contractNumber: string = "";
    startDate: string = "";
    returnDate: string = "";
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = NaN;
    returnMileage2: number = NaN;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = NaN;
    startMileage2: number = NaN;
    isClosed: number = 0;
    customer: ThirdParty = null;
    relatedTable: string = null;

    constructor() {
        super(Movement.TABLENAME);

        this.customer = new ThirdParty();
    }
}
