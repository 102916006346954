import { NgModule } from "@angular/core";
import { GtagDirective } from "./gtag.directive";
import { GtagService } from "./gtag.service";

@NgModule({
    declarations: [
        GtagDirective,
    ],
    imports: [],
    exports: [
        GtagDirective,
    ],
    providers: [
        GtagService,
    ],
})
export class GtagModule {
}
