import { Component, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Angular2SignaturePadComponent, Options } from "../../components/angular2-signaturepad/angular2-signaturepad.component";
import { NavControllerExtended } from "../../helpers/navControllerExtended";

@Component({
    templateUrl: "draw.modal.html",
    styleUrls: ["draw.modal.scss"],
})
export class DrawModal {
    @ViewChild("signaturePad", { static: true }) signaturePad: Angular2SignaturePadComponent;

    public blueColor = "#167BFF";
    public greenColor = "#7CFF17";
    public redColor = "#FF2617";
    public yellowColor = "#F0FF17";

    private _ratio: number = 1;
    private base64: string = "";

    constructor(private navCtrl: NavControllerExtended,
                private modalController: ModalController) {
        this.base64 = this.navCtrl.params.getParam("imageData") as string;
    }

    public ionViewDidEnter() {
        this.signaturePad.set(Options.canvasWidth, window.innerWidth * this._ratio);
        this.signaturePad.set(Options.canvasHeight, window.innerHeight * this._ratio);
        this.signaturePad.set(Options.backgroundColor, "rgb(255,255,255)");
        this.signaturePad.set(Options.minWidth, 3);
        this.signaturePad.set(Options.penColor, this.blueColor);

        if (this.base64.length > 0) {
            this.dimensions();
        }
    }

    public clear() {
        if (this.base64.length > 0) {
            this.signaturePad.clear();
            this.signaturePad.fromDataURL(this.base64);
            this.dimensions();
        }
    }

    public save() {
        this.base64 = this.signaturePad.toDataURL("image/jpeg");
        this.modalController.dismiss({ base64: this.base64 });
    }

    public dismiss() {
        this.modalController.dismiss();
    }

    public setColor(color: string) {
        this.signaturePad.set(Options.penColor, color);
    }

    private dimensions() {
        let canvas = this.signaturePad.$canvas;
        let ctx: CanvasRenderingContext2D = canvas.getContext("2d");

        let image = new Image();
        image.src = this.base64;

        image.onload = () => {
            if (image.naturalHeight > image.naturalWidth) {
                let width: number = canvas.height / (image.naturalHeight / image.naturalWidth);
                this.signaturePad.set(Options.canvasWidth, width);
                ctx.drawImage(image, 0, 0, width, canvas.height);
            } else {
                let height: number = canvas.width / (image.naturalWidth / image.naturalHeight);
                this.signaturePad.set(Options.canvasHeight, height);
                ctx.drawImage(image, 0, 0, canvas.width, height);
            }
        };
    }
}
