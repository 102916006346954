import { Component, Input } from "@angular/core";
import { LtcDto } from "../../../core/dto/ltc.dto";

import { AddressHelper } from "../../../core/helpers/address.helper";

@Component({
    selector: "contract-card",
    templateUrl: "contract-card.component.html",
    styleUrls: ["contract-card.component.scss"],
})
export class ContractCardComponent {
    @Input() ltc: LtcDto;
    @Input() collapsed: boolean = false;

    addressHelper = AddressHelper;
}
