import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { Logger, LoggingService, LoggingServiceConfiguration } from "ionic-logging-service";
import { DateTime } from "luxon";
import { DbDaoModule } from "../core/dao/db/db.dao.module";
import { WsDaoModule } from "../core/dao/ws/ws.dao.module";
import { HttpModule } from "../core/http/http.module";
import { PersistenceModule } from "../core/persistence/persistence.module";
import { ServiceModule } from "../core/services/service.module";
import { environment } from "../environments/environment";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { DirectivesModule } from "../gyzmo-commons/directives/directives.module";
import { GtagModule } from "../gyzmo-commons/gtag/gtag.module";
import { AssetsHelper } from "../gyzmo-commons/helpers/assets.helper";
import { PlatformName } from "../gyzmo-commons/helpers/device.helper";
import { TranslatePoHttpLoader } from "../gyzmo-commons/helpers/i18n/translatePoHttpLoader";
import { PipesModule } from "../gyzmo-commons/helpers/pipes/pipes.module";
import { AngularHttpClient } from "../gyzmo-commons/http/angularHttpClient";
import { AngularHttpClientWithMock } from "../gyzmo-commons/http/angularHttpClientWithMock";
import { DateProvider } from "../gyzmo-commons/interfaces/dateProvider";
import { DrawModalModule } from "../gyzmo-commons/modals/draw/draw.module";
import { CommonNativeModule } from "../gyzmo-commons/native/common.native.module";
import { BrowserCameraModalModule } from "../gyzmo-commons/pages/browser-camera/browserCamera.module";
import { MockDateProvider } from "../gyzmo-commons/providers/mockDateProvider";
import { RealDateProvider } from "../gyzmo-commons/providers/realDateProvider";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APP_OPTIONS, MODULE } from "./app.constants";
import { ContractSearchModalModule } from "./components/contract-search/contract-search.module";
import { CreateServiceEntryModule } from "./modals/create-service-entry/create-service-entry.module";
import { PopoversModule } from "./popovers/popovers.module";

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
    return new TranslatePoHttpLoader(http, "assets/i18n", ".po");
}

export function createLogger(loggingService: LoggingService): Logger {
    let loggerName = "[" + MODULE + "]";
    // logLevel: 'DEBUG' pour voir les requetes SQL
    let config: LoggingServiceConfiguration = { logLevels: [{ loggerName, logLevel: environment.production ? "INFO" : "DEBUG" }] };
    loggingService.configure(config);
    return loggingService.getLogger(loggerName);
}

export function createHttpClient(logger: Logger,
                                 httpClient: HttpClient,
                                 assetsHelper: AssetsHelper,
                                 keyValueDbDao: KeyValueDbDao) {
    return environment.testConfig ? new AngularHttpClientWithMock(logger, httpClient, assetsHelper, keyValueDbDao) : new AngularHttpClient(logger, httpClient);
}

function createDateProvider() {
    return environment.testConfig ? new MockDateProvider(DateTime.local(2020, 8, 24, 12, 0)) : new RealDateProvider();
}

function getAppOptions() {
    let appOptions = APP_OPTIONS;

    if (environment.testConfig && !appOptions["mode"]) {
        appOptions.animated = false;

        switch (environment.testConfig.platform) {
            case PlatformName.IOS:
                appOptions.mode = "ios";
                break;
            default:
                appOptions.mode = "md";
        }
    }

    return appOptions;
}

export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return "~" + params.key;
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        PipesModule,
        DirectivesModule,
        PopoversModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        CommonNativeModule,
        HttpModule,
        DbDaoModule,
        WsDaoModule,
        ServiceModule,
        PersistenceModule,
        AppRoutingModule,
        BrowserCameraModalModule,
        DrawModalModule,
        ContractSearchModalModule,
        CreateServiceEntryModule,
        GtagModule,
        IonicModule.forRoot(getAppOptions()),
    ],
    providers: [
        { provide: AngularHttpClient, useFactory: createHttpClient, deps: [Logger, HttpClient, AssetsHelper, KeyValueDbDao] },
        { provide: DateProvider, useFactory: createDateProvider },
        { provide: Logger, useFactory: createLogger, deps: [LoggingService] },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: "fr-FR" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
