import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { PhoneNumber } from "libphonenumber-js";
import { ConstructionSiteDto } from "../../../core/dto/constructionSite.dto";
import { ThirdPartyDto } from "../../../core/dto/thirdParty.dto";
import { AddressHelper } from "../../../core/helpers/address.helper";
import { ContactType } from "../contact-col/contact-col.component";

@Component({
    selector: "construction-site-card",
    templateUrl: "construction-site-card.component.html",
    styleUrls: ["construction-site-card.component.scss"],
})
export class ConstructionSiteCardComponent {
    @Input() constructionSite: ConstructionSiteDto;
    @Input() prescriber: ThirdPartyDto;
    @Input() collapsed: boolean = false;
    @Input() showGpsButton: boolean = true;
    @Output() constructionSiteChangeClick: EventEmitter<any> = new EventEmitter();

    addressHelper = AddressHelper;

    ContactType = ContactType;

    constructor(private launchNavigator: LaunchNavigator) {
    }

    public call(phoneNumber: PhoneNumber) {
        window.open("tel:" + phoneNumber.number);
    }

    public text(phoneNumber: PhoneNumber) {
        window.open("sms:" + phoneNumber.number);
    }

    public getPrescriber(): ThirdPartyDto {
        return this.prescriber;
    }

    public getConstructionSiteManagerContact(): ThirdPartyDto {
        return this.constructionSite.constructionSiteManager;
    }

    public getWorksManagerContact(): ThirdPartyDto {
        return this.constructionSite.worksManager;
    }

    startGpsApp() {
        this.launchNavigator.navigate(this.addressHelper.formatAddress(this.constructionSite.mainAddress));
    }
}
