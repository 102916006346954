import { NgModule } from "@angular/core";
import { DatePickerPopoverModule } from "../../gyzmo-commons/components/date-picker/date-picker-popover/date-picker-popover.module";
import { TimePickerPopoverModule } from "../../gyzmo-commons/components/time-picker/time-picker-popover/time-picker-popover.module";
import { ProgressDialogPopoverModule } from "../../gyzmo-commons/popovers/progress-dialog/progress-dialog.module";

@NgModule({
    imports: [
        ProgressDialogPopoverModule,
        DatePickerPopoverModule,
        TimePickerPopoverModule,
    ],
    declarations: [],
    providers: [],
})
export class PopoversModule {
}
