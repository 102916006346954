import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { ThirdParty } from "../../models/thirdParty.model";
import { AddressDbDao } from "./address.db.dao";
import { ContactDetailsDbDao } from "./contactDetails.db.dao";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyDbDao extends DbDaoBase<ThirdParty> {
    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private addressDbDao: AddressDbDao,
                private contactDetailsDbDao: ContactDetailsDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ThirdParty.TABLENAME + "_id"
                    + " ON " + ThirdParty.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ThirdParty.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "civility TEXT, "
                    + "firstName TEXT, "
                    + "lastName TEXT, "
                    + "isActive INT, "
                    + "mainAddress TEXT, "
                    + "mail TEXT, "
                    + "mobile TEXT, "
                    + "phone TEXT,"
                    + "company TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ThirdParty.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ThirdParty.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean): Promise<ThirdParty> {
        let selectQuery = "SELECT * FROM " + ThirdParty.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let thirdParty: ThirdParty = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.addressDbDao.get(thirdParty.mainAddress.id, hydrate)
                            .then(value => {
                                thirdParty.mainAddress = value;
                            }));
                        hydratationPromises.push(this.contactDetailsDbDao.get(thirdParty.mail.id, hydrate)
                            .then(value => {
                                thirdParty.mail = value;
                            }));
                        hydratationPromises.push(this.contactDetailsDbDao.get(thirdParty.mobile.id, hydrate)
                            .then(value => {
                                thirdParty.mobile = value;
                            }));
                        hydratationPromises.push(this.contactDetailsDbDao.get(thirdParty.phone.id, hydrate)
                            .then(value => {
                                thirdParty.phone = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return thirdParty;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return ThirdParty.TABLENAME;
    }

    protected rowToModel(row: any): ThirdParty {
        let thirdParty = new ThirdParty();
        thirdParty.id = row.id;
        thirdParty.firstName = row.firstName;
        thirdParty.lastName = row.lastName;
        thirdParty.isActive = row.isActive;
        thirdParty.mainAddress.id = row.mainAddress;
        thirdParty.mail.id = row.mail;
        thirdParty.mobile.id = row.mobile;
        thirdParty.phone.id = row.phone;
        thirdParty.company = JSON.parse(row.company);
        thirdParty.civility = JSON.parse(row.civility);

        return thirdParty;
    }

    public save(thirdParty: ThirdParty): Promise<ThirdParty> {
        let promises = [];

        if (thirdParty.mainAddress) {
            promises.push(this.addressDbDao.save(thirdParty.mainAddress));
        }

        if (thirdParty.mail) {
            promises.push(this.contactDetailsDbDao.save(thirdParty.mail));
        }

        if (thirdParty.mobile) {
            promises.push(this.contactDetailsDbDao.save(thirdParty.mobile));
        }

        if (thirdParty.phone) {
            promises.push(this.contactDetailsDbDao.save(thirdParty.phone));
        }

        return Promise.all(promises)
            .then(() => {
                let query = "INSERT OR REPLACE INTO " + ThirdParty.TABLENAME + " (id, civility, firstName, lastName, isActive, mainAddress, mail, mobile, phone, company) VALUES ("
                            + this.getValue(thirdParty.id)
                            + this.getValueAsJsonString(thirdParty.civility)
                            + this.getValue(thirdParty.firstName)
                            + this.getValue(thirdParty.lastName)
                            + this.getValue(thirdParty.isActive)
                            + this.getFkValue(thirdParty.mainAddress)
                            + this.getFkValue(thirdParty.mail)
                            + this.getFkValue(thirdParty.mobile)
                            + this.getFkValue(thirdParty.phone)
                            + this.getValueAsJsonString(thirdParty.company, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return thirdParty;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
