import { NgModule } from "@angular/core";
import { CommonPersistenceModule } from "../../gyzmo-commons/persistence/common.persistence.module";
import { DaoProvider } from "./dao.provider";

@NgModule({
    imports: [
        CommonPersistenceModule,
    ],
    providers: [
        DaoProvider,
    ],
})
export class PersistenceModule {
}
