import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PictureHelper } from "./picture.helper";

@Injectable({
    providedIn: "root",
})
export class AssetsHelper {

    constructor(private http: HttpClient) {
    }

    public async exists(filename: string): Promise<boolean> {
        return this.http.get("../../assets/" + filename, {
            responseType: "arraybuffer",
        }).toPromise()
            .then(response => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    public async readFile(filename: string): Promise<any> {
        return this.http.get<string>("../../assets/" + filename)
            .toPromise()
            .then(response => {
                return response;
            });
    }

    public async readBinaryFile(filename: string): Promise<ArrayBuffer> {
        return this.http.get("../../assets/" + filename, {
            responseType: "arraybuffer",
        }).toPromise();
    }

    public async readFileAsText(filename: string): Promise<string> {
        return new Promise<string>(resolve => {
            let subscription = this.http.get("../../assets/" + filename, { responseType: "text" })
                .subscribe(value => {
                        subscription.unsubscribe();
                        resolve(value);
                    },
                    () => {
                        resolve(null);
                    });
        });
    }

    public async readImageAsBase64(filename: string): Promise<string> {
        return this.http.get("../../assets/" + filename, { responseType: "arraybuffer" })
            .toPromise()
            .then(value => {
                return PictureHelper.encodeToBase64(value);
            });
    }
}
