import { Injectable } from "@angular/core";
import { WsDaoBase } from "../../../gyzmo-commons/dao/ws/base/ws.dao.base";
import { MovementDto } from "../../dto/movement.dto";
import { ServerConnection } from "../../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class MovementWsDao extends WsDaoBase<MovementDto> {
    static WS = "movements";

    public getById(serverConnection: ServerConnection, id: string): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, MovementWsDao.WS + "/:id", tokens)
                .then(response => {
                    let movement = MovementDto.fromBody(response.body);

                    let promises = [];
                    Promise.all(promises)
                        .then(ignored => {
                            resolve(movement);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, movement: MovementDto): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", movement.id);

            serverConnection.put(this.constructor.name, MovementWsDao.WS + "/:id", tokens, movement.toBody())
                .then(response => {
                    resolve(movement);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
