import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
    providedIn: "root",
})
export class OpenMapsHelper {
    constructor(private platform: Platform) {
    }

    public launchMapsApp(latitude: any, longitude: any) {
        let destination = latitude + "," + longitude;

        if (this.platform.is("ios")) {
            window.open("maps://?q=" + destination, "_system");
        } else if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
            window.open("https://www.google.com/maps/?q=" + destination);
        } else {
            let label = encodeURI("Emplacement de mon véhicule");
            window.open("geo:0,0?q=" + destination + "(" + label + ")", "_system");
        }
    }
}
