import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "../../../helpers/pipes/pipes.module";
import { CalendarComponent } from "./calendar.component";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        PipesModule,
    ],
    declarations: [
        CalendarComponent,
    ],
    exports: [
        CalendarComponent,
    ],
})
export class CalendarModule {
}
