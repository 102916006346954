export abstract class BaseDto<T> {
    public static getId(object: any): string {
        if (object.id) {
            return object.id;
        }

        return object;
    }

    public abstract toModel(): T;

    public abstract toBody(): any;

    protected getId(object: any): string {
        return BaseDto.getId(object);
    }

    protected clone(): any {
        return JSON.parse(JSON.stringify(this));
    }
}
