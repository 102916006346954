import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { ConstructionSite } from "../../models/constructionSite.model";
import { AddressDbDao } from "./address.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class ConstructionSiteDbDao extends DbDaoBase<ConstructionSite> {
    constructor(
        logger: Logger,
        private sqlProvider: AppSqlProvider,
        private addressDbDao: AddressDbDao,
        private thirdPartyDbDao: ThirdPartyDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ConstructionSite.TABLENAME + "_id"
                    + " ON " + ConstructionSite.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ConstructionSite.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "siteName TEXT, "
                    + "mainAddress TEXT, "
                    + "worksManager TEXT, "
                    + "constructionSiteManager TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ConstructionSite.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ConstructionSite.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean): Promise<ConstructionSite> {
        let selectQuery = "SELECT * FROM " + ConstructionSite.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let constructionSite: ConstructionSite = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.addressDbDao.get(constructionSite.mainAddress.id, hydrate)
                            .then(value => {
                                constructionSite.mainAddress = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(constructionSite.worksManager.id, hydrate)
                            .then(value => {
                                constructionSite.worksManager = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(constructionSite.constructionSiteManager.id, hydrate)
                            .then(value => {
                                constructionSite.constructionSiteManager = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return constructionSite;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return ConstructionSite.TABLENAME;
    }

    protected rowToModel(row: any): ConstructionSite {
        let constructionSite = new ConstructionSite();
        constructionSite.id = row.id;
        constructionSite.siteName = row.siteName;

        constructionSite.mainAddress.id = row.mainAddress;
        constructionSite.worksManager.id = row.worksManager;
        constructionSite.constructionSiteManager.id = row.constructionSiteManager;

        return constructionSite;
    }

    public save(constructionSite: ConstructionSite): Promise<ConstructionSite> {
        let promises = [];

        if (constructionSite.mainAddress) {
            promises.push(this.addressDbDao.save(constructionSite.mainAddress));
        }

        if (constructionSite.worksManager) {
            promises.push(this.thirdPartyDbDao.save(constructionSite.worksManager));
        }

        if (constructionSite.constructionSiteManager) {
            promises.push(this.thirdPartyDbDao.save(constructionSite.constructionSiteManager));
        }

        return Promise.all(promises)
            .then(ignored => {
                let query = "INSERT OR REPLACE INTO " + ConstructionSite.TABLENAME + " ("
                            + "id, siteName, mainAddress, worksManager, constructionSiteManager"
                            + ") VALUES ("
                            + this.getValue(constructionSite.id)
                            + this.getValue(constructionSite.siteName)
                            + this.getFkValue(constructionSite.mainAddress)
                            + this.getFkValue(constructionSite.worksManager)
                            + this.getFkValue(constructionSite.constructionSiteManager, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return constructionSite;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
