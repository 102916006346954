import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { ServiceEntry } from "../models/serviceEntry";
import { BaseDto } from "./base/base.dto";

export class ServiceEntryDto extends BaseDto<ServiceEntry> {
    public id: string = "";
    public visitDate: DateTime = null;
    public quantity: number = 0;
    public additionalQuantity: number = 0;
    public contractId: string = "";

    public driver: { id: string } = { id: null };
    public service: { id: string } = { id: null };
    public status: { id: string } = { id: null };

    static fromBody(body: any, thirdPartId: string): ServiceEntryDto {
        let serviceEntryDto = new ServiceEntryDto();

        serviceEntryDto.id = body.id;
        serviceEntryDto.visitDate = DateHelper.tryFromFormat(body.visitDate, DATE_NODEJS_FORMAT);
        serviceEntryDto.quantity = body.quantity;
        serviceEntryDto.additionalQuantity = body.additionalQuantity;
        serviceEntryDto.contractId = body.contractId;

        if (body.service) {
            serviceEntryDto.service.id = body.service.id;
        }

        if (body.serviceEntryDto) {
            serviceEntryDto.status.id = body.status.id;
        }

        serviceEntryDto.driver.id = thirdPartId;

        return serviceEntryDto;
    }

    static fromModel(serviceEntry: ServiceEntry) {
        let serviceEntryDto = new ServiceEntryDto();

        serviceEntryDto.id = serviceEntry.id;
        serviceEntryDto.visitDate = DateTime.fromISO(serviceEntry.visitDate);
        serviceEntryDto.quantity = serviceEntry.quantity;
        serviceEntryDto.additionalQuantity = serviceEntry.additionalQuantity;
        serviceEntryDto.contractId = serviceEntry.contractId;

        serviceEntryDto.service = serviceEntry.service;
        serviceEntryDto.status = serviceEntry.status;
        serviceEntryDto.driver = serviceEntry.driver;

        return serviceEntryDto;
    }

    public toModel(): ServiceEntry {
        let serviceEntry = new ServiceEntry();

        serviceEntry.id = this.id;
        serviceEntry.visitDate = DateHelper.tryToISO(this.visitDate);
        serviceEntry.quantity = this.quantity;
        serviceEntry.additionalQuantity = this.additionalQuantity;
        serviceEntry.contractId = this.contractId;

        serviceEntry.service = this.service;
        serviceEntry.status = this.status;
        serviceEntry.driver = this.driver;

        return serviceEntry;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.visitDate = DateHelper.tryToFormat(this.visitDate, DATE_NODEJS_FORMAT);

        return clone;
    }
}
