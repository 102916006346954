import { NgModule } from "@angular/core";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { CommonServiceModule } from "../../gyzmo-commons/services/common.service.module";
import { DbDaoModule } from "../dao/db/db.dao.module";
import { WsDaoModule } from "../dao/ws/ws.dao.module";
import { AttachmentService } from "./attachment.service";
import { CompanyService } from "./company.service";
import { ContactDetailsService } from "./contactDetails.service";
import { DefaultDataDownloaderService } from "./defaultDataDownloader.service";
import { FileService } from "./file.service";
import { HourTypeService } from "./hourType.service";
import { LtcService } from "./ltc.service";
import { OfflineModeService } from "./offlineMode.service";
import { TimeEntryService } from "./timeEntry.service";
import { UserService } from "./user.service";

@NgModule({
    imports: [
        DbDaoModule,
        WsDaoModule,
        CommonServiceModule,
    ],
    providers: [
        File,
        FileOpener,
        AttachmentService,
        CompanyService,
        ContactDetailsService,
        DefaultDataDownloaderService,
        FileService,
        HourTypeService,
        LtcService,
        OfflineModeService,
        TimeEntryService,
        UserService,
    ],
})
export class ServiceModule {
}
