import { Injectable } from "@angular/core";
import { LtcDbDao } from "../dao/db/ltc.db.dao";
import { LtcWsDao } from "../dao/ws/ltc.ws.dao";
import { LtcDto } from "../dto/ltc.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable()
export class LtcService {
    constructor(private ltcWsDao: LtcWsDao,
                private ltcDbDao: LtcDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronize(thirdPartyId: string): Promise<LtcDto[]> {
        return this.ltcWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection(), thirdPartyId)
            .then(ltcDtos => {
                let promises = [];
                ltcDtos.forEach(ltcDto => {
                    promises.push(this.ltcDbDao.save(ltcDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return ltcDtos;
                    });
            });
    }
}
