import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class ServiceType extends ModelBase {
    static TABLENAME = "ServiceType";

    public id: string = "";
    public wording: string = "";

    constructor() {
        super(ServiceType.TABLENAME);
    }
}
