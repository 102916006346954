import { User } from "../models/user.model";
import { BaseDto } from "./base/base.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class UserDto extends BaseDto<User> {
    public id: string = "";
    public username: string = "";
    public thirdParty: ThirdPartyDto = null;
    public language: string = "";
    public wording: string = "";
    public password: string = "";
    public company: { id: string } = { id: null };
    public profile: { id: string } = { id: null };

    constructor() {
        super();
        this.thirdParty = new ThirdPartyDto();
    }

    public static fromBody(body: any): UserDto {
        let user = new UserDto();

        user.id = body.id;
        user.username = body.username;
        user.language = body.language;
        user.wording = body.wording;
        user.password = body.password;

        if (body.company) {
            user.company = body.company;
        }

        if (body.profile) {
            user.profile = body.profile;
        }

        if (body.thirdParty) {
            user.thirdParty = ThirdPartyDto.fromBody(body.thirdParty);
        }

        return user;
    }

    static fromModel(user: User): UserDto {
        let userDto = new UserDto();

        userDto.id = user.id;
        userDto.username = user.username;
        userDto.language = user.language;
        userDto.wording = user.wording;
        userDto.password = user.password;
        userDto.company = user.company;
        userDto.profile = user.profile;

        if (user.thirdParty) {
            userDto.thirdParty = ThirdPartyDto.fromModel(user.thirdParty);
        }

        return userDto;
    }

    toModel(): User {
        let user = new User();

        user.id = this.id;
        user.username = this.username;
        user.language = this.language;
        user.wording = this.wording;
        user.password = this.password;
        user.company = this.company;
        user.profile = this.profile;

        user.thirdParty = this.thirdParty.toModel();

        return user;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.thirdParty = this.thirdParty.toBody();

        return clone;
    }
}
