import { Component, EventEmitter, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LtcDto } from "../../../core/dto/ltc.dto";
import { LtcService } from "../../../core/services/ltc.service";
import { UserService } from "../../../core/services/user.service";
import { DATE_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";

@Component({
    selector: "contract-search",
    templateUrl: "contract-search.component.html",
    styleUrls: ["contract-search.component.scss"],
})
export class ContractSearchComponent {
    @Output() contractSelected: EventEmitter<LtcDto> = new EventEmitter();

    public searchTerm: string = "";
    public showHelp: boolean = false;
    public searching: boolean = false;
    public ltcs: LtcDto[];
    public filteredLtcs: LtcDto[];

    constructor(private modalController: ModalController,
                private dateProvider: DateProvider,
                private ltcService: LtcService,
                private userService: UserService) {

        this.searching = true;

        this.userService.getCurrentUser(false).then(user => {
            this.ltcService.synchronize(user.thirdParty.id).then(ltcs => {
                this.ltcs = ltcs.sort((a, b) => {
                    return b.contractNumber.localeCompare(a.contractNumber);
                });
                this.filteredLtcs = this.ltcs;
                this.searching = false;
            });
        });
    }

    public dismiss(data?: any) {
        this.modalController.dismiss(data);
    }

    public search(): LtcDto[] {
        this.searching = true;

        this.filteredLtcs = this.ltcs.filter(
            (ltc) => {
                return ((ltc.bookingCode && (ltc.bookingCode.toUpperCase().indexOf(this.searchTerm.toUpperCase()) != -1))
                        || (ltc.contractNumber && (ltc.contractNumber.toUpperCase().indexOf(this.searchTerm.toUpperCase()) != -1))
                        || (ltc.customer && (ltc.customer.firstName.toUpperCase().indexOf(this.searchTerm.toUpperCase()) != -1))
                        || (ltc.customer && (ltc.customer.lastName.toUpperCase().indexOf(this.searchTerm.toUpperCase()) != -1))
                        || (ltc.constructionSite && (ltc.constructionSite.siteName.toUpperCase().indexOf(this.searchTerm.toUpperCase()) != -1))
                        || (ltc.startDate && (ltc.startDate.toFormat(DATE_FORMAT).indexOf(this.searchTerm) != -1))
                );

            },
        );
        this.searching = false;

        return this.filteredLtcs;
    }

    startWithContract(ltc: LtcDto) {
        if (this.contractSelected) {
            this.contractSelected.emit(ltc);
        }
    }

    public setCurrentDate() {
        this.searchTerm = this.dateProvider.now().toFormat(DATE_FORMAT);
    }
}
