import { HourType } from "../models/hourType";
import { BaseDto } from "./base/base.dto";

export class HourTypeDto extends BaseDto<HourType> {
    public id: string = "";
    public kind: string = "";
    public wording: string = "";

    static fromBody(body: any, kind: string): HourTypeDto {
        let hourTypeDto = new HourTypeDto();

        hourTypeDto.id = body.id;
        hourTypeDto.kind = kind;
        hourTypeDto.wording = body.wording;

        return hourTypeDto;
    }

    static fromModel(hourType: HourType) {
        let hourTypeDto = new HourTypeDto();

        hourTypeDto.id = hourType.id;
        hourTypeDto.kind = hourType.kind;
        hourTypeDto.wording = hourType.wording;

        return hourTypeDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): HourType {
        let hourType = new HourType();

        hourType.id = this.id;
        hourType.kind = this.kind;
        hourType.wording = this.wording;

        return hourType;
    }
}
