import { Injectable } from "@angular/core";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { Logger } from "ionic-logging-service";
import { DeviceHelper, PlatformName } from "../helpers/device.helper";
import { isNullOrEmpty } from "../helpers/null.helper";
import { DatabaseLocation } from "../interfaces/databaseLocation";
import { SQLiteResult, SqlProvider } from "../interfaces/sqlProvider";
import { DatabaseEventService } from "./databaseEvent.service";
import { JeepSqlBrowserSqlProvider } from "./sql.browser.jeepsql.provider";
import { WebSqlBrowserSqlProvider } from "./sql.browser.websql.provider";
import { NativeCipherSqlProvider } from "./sql.native.cipher.provider";
import { NativeSqlProvider } from "./sql.native.provider";

const win: any = window;

@Injectable({
    providedIn: "root",
})
export class AppSqlProvider implements SqlProvider {
    private sqlProvider: SqlProvider;

    constructor(private logger: Logger,
                private databaseEventService: DatabaseEventService,
                private deviceHelper: DeviceHelper,
                private sqlite: SQLite) {
    }

    public close(): Promise<boolean> {
        return this.sqlProvider.close();
    }

    public commitBulk(): Promise<void> {
        return this.sqlProvider.commitBulk();
    }

    public disableBulkWriting() {
        this.sqlProvider.disableBulkWriting();
    }

    public dropDatabase() {
        return this.sqlProvider.dropDatabase();
    }

    public enableBulkWriting() {
        this.sqlProvider.enableBulkWriting();
    }

    public getDbName(): string {
        return this.sqlProvider.getDbName();
    }

    public getExistingTables() {
        return this.sqlProvider.getExistingTables();
    }

    public initialize(dbName: string, location: DatabaseLocation, key?: string): Promise<boolean> {
        if (!this.sqlProvider) {
            if (this.deviceHelper.isRunningOnDevice()) {
                // Pas de base cryptée sur iOS, pour pouvoir publier il nous faudrait forcément déposer un dossier auprès de l'ANSSI
                // https://discuss.zetetic.net/t/export-requirements-for-applications-using-sqlcipher/47
                if (isNullOrEmpty(key) || this.deviceHelper.getPlatform() == PlatformName.IOS) {
                    this.sqlProvider = new NativeSqlProvider(this.logger,
                        this.deviceHelper,
                        this.sqlite);
                } else {
                    this.sqlProvider = new NativeCipherSqlProvider(this.logger,
                        this.deviceHelper,
                        this.sqlite,
                        key);
                }
            } else {
                if (win.openDatabase) {
                    this.sqlProvider = new WebSqlBrowserSqlProvider(this.logger,
                        this.deviceHelper);
                } else {
                    this.sqlProvider = new JeepSqlBrowserSqlProvider(this.logger,
                        this.databaseEventService,
                        this.deviceHelper);
                }
            }
        }

        return this.sqlProvider.initialize(dbName, location);
    }

    public isInitialized(): boolean {
        return this.sqlProvider.isInitialized();
    }

    public query(queryText: string, bulkable: boolean = true): Promise<SQLiteResult> {
        return this.sqlProvider.query(queryText, bulkable);
    }
}
