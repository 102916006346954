import { Injectable } from "@angular/core";
import { WsDaoBase } from "../../../gyzmo-commons/dao/ws/base/ws.dao.base";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { TimeEntryDto } from "../../dto/timeEntry.dto";
import { ServerConnection } from "../../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class TimeEntryWsDao extends WsDaoBase<TimeEntryDto> {
    static WS = "driver-hours";

    public getById(serverConnection: ServerConnection, id: string): Promise<TimeEntryDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection, ltcId: string, thirdPartyId: string): Promise<TimeEntryDto[]> {
        return new Promise<TimeEntryDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let subHourKind = 'HRS_CHAUF_NOR';
            let url = "long-term-contracts/" + ltcId + "/" + TimeEntryWsDao.WS + "?_limit=NOLIMIT&driver=" + thirdPartyId + "&subHourKind=" + subHourKind;

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    let timeEntryDtos: TimeEntryDto[] = [];
                    if (response.body) {
                        (response.body as Array<any>).forEach(value => {
                            let timeEntryDto = TimeEntryDto.fromBody(value, thirdPartyId);
                            timeEntryDtos.push(timeEntryDto);
                        });
                    }

                    resolve(timeEntryDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, timeEntry: TimeEntryDto): Promise<TimeEntryDto> {
        return new Promise<TimeEntryDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", timeEntry.id);
            tokens.set("contractId", timeEntry.contractId);

            if (!isNullOrEmpty(timeEntry.id)) {
                serverConnection.put(this.constructor.name, "long-term-contracts/:contractId/" + TimeEntryWsDao.WS + "/:id", tokens, timeEntry.toBody())
                    .then(response => {
                        resolve(timeEntry);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                serverConnection.post(this.constructor.name, "long-term-contracts/:contractId/" + TimeEntryWsDao.WS, tokens, timeEntry.toBody())
                    .then(response => {
                        timeEntry.id = response.headers.get(XVEGAID.toLowerCase());
                        resolve(timeEntry);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }
}
