import { NgModule } from "@angular/core";
import { LoggingService } from "ionic-logging-service";
import { AppVersionService } from "./appVersion.service";
import { BarcodeScannerService } from "./barcodeScanner.service";
import { CacheService } from "./cache.service";
import { CameraService } from "./camera.service";
import { GeocodingService } from "./geocoding.service";
import { GeolocationService } from "./geolocation.service";
import { LanguageService } from "./language.service";

@NgModule({
    imports: [],
    providers: [
        AppVersionService,
        BarcodeScannerService,
        CacheService,
        CameraService,
        GeocodingService,
        GeolocationService,
        LanguageService,
        LoggingService,
    ],
})
export class CommonServiceModule {
}
