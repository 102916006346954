import { Injectable } from "@angular/core";
import { BarcodeScanner, BarcodeScanResult } from "@awesome-cordova-plugins/barcode-scanner/ngx";

@Injectable({
    providedIn: "root",
})
export class BarcodeScannerService {
    constructor(private barcodeScanner: BarcodeScanner) {
    }

    public async scan(): Promise<{ text: string }> {
        return this.barcodeScanner.scan()
            .then((barcodeData: BarcodeScanResult) => {
                if (barcodeData) {
                    return { text: barcodeData.text };
                } else {
                    return null;
                }
            }).catch(err => {
                return null;
            });
    }
}
